<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<!-- Kothram List Table -->
<div class="container-fluid" *ngIf="kothramList.length >= 1">
  <h5 class="kothramheading">kothram List</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterKothramList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button
                  type="button"
                  class="btn btn-success btn-rounded mb-2 me-2"
                  (click)="openModal(content)"
                >
                  <i class="mdi mdi-plus mr-1"></i> Add
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredKothramList"
                  id="{{ item.gotra }}"
                >
                  <td>
                    {{ item.gotra }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="editDataGet(item, content)"
                        ><i class="mdi mdi-pencil font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-danger"
                        (click)="delete(item)"
                        ><i class="mdi mdi-delete font-size-18"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Kothram List Form Create Model -->
<ng-template #content role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add Kulam and Kothram</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="[modal.dismiss('Cross click'), closeForm()]"
    ></button>
  </div>
  <form
    (ngSubmit)="[onSubmit(), modal.close('Close click')]"
    [formGroup]="KothramForm"
  >
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">Kulam</label>

            <ng-select
              [items]="kulamList"
              bindLabel="kula"
              bindValue="id"
              formControlName="kula_id"
              [multiple]="false"
              [clearable]="!isKulamDropdownClearable()"
              [ngClass]="{ 'is-invalid': submitted && f.kula_id.errors }"
            ></ng-select>

            <div *ngIf="submitted && f.kula_id.errors" class="invalid-feedback">
              <span *ngIf="f.kula_id.errors.required"
                >Please select a kulam.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">Kothram</label>

            <input
              type="text"
              class="form-control"
              id="gotra"
              formControlName="gotra"
              placeholder="Kothram"
              [ngClass]="{ 'is-invalid': submitted && f.gotra.errors }"
            />
            <div *ngIf="submitted && f.gotra.errors" class="invalid-feedback">
              <span *ngIf="f.gotra.errors.required">
                Please provide a Kothram</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="[modal.close('Close click'), closeForm()]"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn">Add</button>
      </div>
    </div>
  </form>
</ng-template>
<!--End Modal -->

<!-- Kothram Approve Table -->
<div
  class="container-fluid"
  *ngIf="userTypeId === '2' && unApprovedKothramList.length >= 1"
>
  <h5 class="kothramheading">Approval kothram</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTermUnApproved"
                    (input)="filterUnApprovedKothramList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredUnApprovedKothramList"
                  id="{{ item.gotra }}"
                >
                  <td>
                    {{ item.gotra }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="editApprovalKulam(item, contentapproval)"
                        ><i class="mdi mdi-pencil font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="approve(item)"
                        ><i class="mdi mdi-check font-size-18"></i> Approve</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Approval Kothram Form Create Model -->
<ng-template #contentapproval let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"></h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="[modal.dismiss('Cross click'), closeForm()]"
    ></button>
  </div>
  <form
    (ngSubmit)="[onSubmitApprovalKothram(), modal.close('Close click')]"
    [formGroup]="KothramForm"
  >
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">Kulam</label>

            <ng-select
              [items]="kulamList"
              bindLabel="kula"
              bindValue="id"
              formControlName="kula_id"
              [multiple]="false"
              [clearable]="!isKulamDropdownClearable()"
              [ngClass]="{ 'is-invalid': submitted && f.kula_id.errors }"
            ></ng-select>

            <div *ngIf="submitted && f.kula_id.errors" class="invalid-feedback">
              <span *ngIf="f.kula_id.errors.required"
                >Please select a kulam.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">Kothram</label>

            <input
              type="text"
              class="form-control"
              id="gotra"
              formControlName="gotra"
              placeholder="Kothram"
              [ngClass]="{ 'is-invalid': submitted && f.gotra.errors }"
            />
            <div *ngIf="submitted && f.gotra.errors" class="invalid-feedback">
              <span *ngIf="f.gotra.errors.required">
                Please provide a Kothram</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="[modal.close('Close click'), closeForm()]"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn"></button>
      </div>
    </div>
  </form>
</ng-template>

<!-- No data has been found the below div will shown on the component -->
<div
  class="account-pages my-5 pt-5"
  *ngIf="kothramList.length < 1 && unApprovedKothramList.length < 1"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <div class="mt-5 text-center">
            <h4 class="text-primary">
              Sorry, No kothram details has been assigned.
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
