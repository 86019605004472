import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";

@Component({
  selector: "app-passwordreset",
  templateUrl: "./passwordreset.component.html",
  styleUrl: "./passwordreset.component.scss",
})
export class PasswordresetComponent {
  //Below all functionality are added by Giriprasath R

  passwordResetForm: FormGroup;
  submitted = false;
  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(private formBuilder: FormBuilder, private router: Router) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.passwordResetForm.controls;
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.passwordResetForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  onSubmit() {
    this.submitted = true;
    // Custom validator to check if password and confirm password match
    if (this.passwordResetForm.valid) {
      const passwordControl = this.passwordResetForm.get("password");
      const confirmPasswordControl =
        this.passwordResetForm.get("confirmPassword");
      if (passwordControl.value === confirmPasswordControl.value) {
        const navigationExtras: NavigationExtras = {
          state: {
            email: this.passwordResetForm.value.email,
            password: this.passwordResetForm.value.password,
          },
        };
        this.router.navigate(["/otpverify"], navigationExtras);
      } else {
        alert("Password Mismatch");
      }
    }
  }

  //Forgot page passwordVisibility
  passwordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    const passwordInput = document.getElementById(
      "password"
    ) as HTMLInputElement;
    if (this.passwordVisible) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }

  //Forgot page confirm passwordVisibility
  confirmPasswordVisibility() {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
    const passwordInput = document.getElementById(
      "confirmPassword"
    ) as HTMLInputElement;
    if (this.confirmPasswordVisible) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }
}
