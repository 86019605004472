import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";
import { NgSelectComponent } from "@ng-select/ng-select";

import Swal from "sweetalert2";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrl: "./signup.component.scss",
})
export class SignupComponent implements OnInit {
  //Below all functionality are added by Giriprasath R

  kulamList: any[] = []; // storing the kulamlist in the array
  gotramList: any[] = []; // storing the gotramlist in the array
  templeList: any[] = []; // storing the templelist in the array
  countryList: any[] = []; // storing the countrylist in the array
  statesList: any[] = []; // storing the stateslist in the array
  cityList: any[] = []; // storing the citylist in the array
  signupForm: FormGroup;
  submitted = false;
  passwordVisible: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiServices: ApiService
  ) {}

  ngOnInit() {
    this.kulam();
    this.country();

    this.signupForm = this.formBuilder.group({
      gender: ["", Validators.required],
      name: ["", Validators.required],
      father_name: ["", Validators.required],
      password: ["", [Validators.required, Validators.minLength(8)]],
      phonenumber: [""],
      dob: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      temple_id: ["", Validators.required],
      kula_id: ["", Validators.required],
      gotra_id: ["", Validators.required],
      city_id: ["", Validators.required],
      state_id: ["", Validators.required],
      country_id: ["", Validators.required],
      phone_number: [
        "",
        [Validators.required, Validators.pattern(/^[0-9]{10}$/)],
      ],
      kula: [""],
    });
  }

  //create new kulam(add) by user on the time of registration
  addKulam = (_item: any, select: NgSelectComponent) => {
    var createKulam: FormGroup;
    createKulam = this.formBuilder.group({
      kula: _item,
    });
    this.apiServices.createKulam(createKulam.value).subscribe((response) => {
      const newKulamItem = response.data.kula;
      this.kulam();
      this.f.controls["kula_id"].setValue(newKulamItem.id);

      // Close the dropdown after adding the item
      select.close();
    });
  };

  //create new kothram(add) by user on the time of registration
  addKothram = (_item: any, select: NgSelectComponent) => {
    const kulaID = this.signupForm.value.kula_id;
    if (
      kulaID == null ||
      kulaID == "null" ||
      kulaID == undefined ||
      kulaID == "undefined" ||
      kulaID == ""
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire("Please select your kulam");
    } else {
      var createKothram: FormGroup;
      createKothram = this.formBuilder.group({
        gotra: _item,
        kula_id: kulaID,
      });
      this.apiServices
        .createKothram(createKothram.value)
        .subscribe((response) => {
          const newkothramItem = response.data.gotra;
          this.kothram(event);
          this.f.controls["gotra_id"].setValue(newkothramItem.id);

          // Close the dropdown after adding the item
          select.close();
        });
    }
  };

  //create new temple(add) by user on the time of registration
  addTemple = (_item: any, select: NgSelectComponent) => {
    const cityID = this.signupForm.value.city_id;
    if (
      cityID == null ||
      cityID == "null" ||
      cityID == undefined ||
      cityID == "undefined" ||
      cityID == ""
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire("Please select your city");
    } else {
      var createTemple: FormGroup;
      createTemple = this.formBuilder.group({
        temple: _item,
        city_id: cityID,
        address: "",
      });
      this.apiServices
        .createTemple(createTemple.value)
        .subscribe((response) => {
          const newTempleItem = response.data.temple;
          this.temple(event);
          this.f.controls["temple_id"].setValue(newTempleItem.id);

          // Close the dropdown after adding the item
          select.close();
        });
    }
  };

  //get the Kulamlist value for the field
  kulam() {
    this.apiServices
      .getKulamList(NetworkService.getKulam())
      .subscribe((response) => {
        this.kulamList = response.data.kulas;
      });
  }

  //get the kothramlist value for the field
  kothram(event: any) {
    if (
      this.signupForm.value.kula_id == null ||
      this.signupForm.value.kula_id == "null" ||
      this.signupForm.value.kula_id == undefined ||
      this.signupForm.value.kula_id == "undefined"
    ) {
      this.apiServices.getGotramById("").subscribe((response) => {
        this.gotramList = response.data.gotras;
      });
    } else {
      this.apiServices
        .getGotramById(this.signupForm.value.kula_id)
        .subscribe((response) => {
          this.gotramList = response.data.gotras;
        });
    }
  }

  //get the Templelist value for the field
  temple(event: any) {
    if (
      this.signupForm.value.city_id == null ||
      this.signupForm.value.city_id == "null" ||
      this.signupForm.value.city_id == undefined ||
      this.signupForm.value.city_id == "undefined"
    ) {
      this.apiServices.getTempleById("").subscribe((response) => {
        this.templeList = response.data.temples;
      });
    } else {
      this.apiServices
        .getTempleById(this.signupForm.value.city_id)
        .subscribe((response) => {
          this.templeList = response.data.temples;
        });
    }
  }

  //get the Countrylist value for the field
  country() {
    this.apiServices
      .getCountryList(NetworkService.getCountry())
      .subscribe((response) => {
        this.countryList = response.data.countries;
      });
  }

  //get the Statelist value for the field
  state(event: any) {
    this.apiServices
      .getStateList(this.signupForm.value.country_id)
      .subscribe((response) => {
        this.statesList = response.data.states;
      });
  }

  //get the Citylist value for the field
  city(event: any) {
    this.apiServices
      .getCityList(this.signupForm.value.state_id)
      .subscribe((response) => {
        this.cityList = response.data.cities;
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signupForm.controls;
  }

  // Modify the date value
  convertToUTC(dateValue: Date): string {
    // Provided date string
    const dateString = dateValue;

    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Add one day to the date
    date.setDate(date.getDate() + 1);

    // Convert the date to a string in ISO 8601 format (YYYY-MM-DDTHH:MM:SS.sssZ)
    const adjustedDateString = date.toISOString().split("T")[0];
    return adjustedDateString;
  }

  //signup page passwordVisibility
  passwordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    const passwordInput = document.getElementById(
      "password"
    ) as HTMLInputElement;
    if (this.passwordVisible) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }

  /**
   * On submit form
   */

  onSubmit() {
    //Throw an error if gender is not selected
    const genderValue = this.signupForm.get("gender").value;
    if (genderValue === "") {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire("Please select your Gender");
    } else if (this.signupForm.invalid) {
      this.submitted = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else {
      var postSignupForm: FormGroup;
      postSignupForm = this.formBuilder.group({
        gotra_id: this.signupForm.value.gotra_id,
        kula_id: this.signupForm.value.kula_id,
        temple_id: this.signupForm.value.temple_id,
        name: this.signupForm.value.name,
        father_name: this.signupForm.value.father_name,
        email: this.signupForm.value.email,
        password: this.signupForm.value.password,
        dob: this.convertToUTC(this.signupForm.value.dob),
        gender: this.signupForm.value.gender,
        phone_number: this.signupForm.value.phone_number,
        city_id: this.signupForm.value.city_id,
      });

      //post the signup form value in the DB
      this.apiServices.register(postSignupForm.value).subscribe((response) => {
        if (response.error == false) {
          //send the email value to the otpverifyComponent
          const navigationExtras: NavigationExtras = {
            state: {
              email: this.signupForm.value.email,
              password: this.signupForm.value.password,
            },
          };
          this.signupForm.reset();
          this.router.navigate(["/otpverify"], navigationExtras);
        }
      });
    }
  }

  // Enable the clearable symbol in country dorpdown
  isCountryDropdownClearable(): boolean {
    const selectedCountry = this.countryList.find(
      (country) => country.id === this.signupForm.get("country_id").value
    );
    return !selectedCountry || !selectedCountry.country;
  }

  // Enable the clearable symbol in state dorpdown
  isStateDropdownClearable(): boolean {
    const selectedState = this.statesList.find(
      (state) => state.id === this.signupForm.get("state_id").value
    );
    return !selectedState || !selectedState.state_name;
  }

  // Enable the clearable symbol in city dorpdown
  isCityDropdownClearable(): boolean {
    const selectedCity = this.cityList.find(
      (city) => city.id === this.signupForm.get("city_id").value
    );
    return !selectedCity || !selectedCity.city_name;
  }

  // Enable the clearable symbol in temple dorpdown
  isTempleDropdownClearable(): boolean {
    const selectedTemple = this.templeList.find(
      (temple) => temple.id === this.signupForm.get("temple_id").value
    );
    return !selectedTemple || !selectedTemple.temple;
  }

  // Enable the clearable symbol in kulam dorpdown
  isKulamDropdownClearable(): boolean {
    const selectedKulam = this.kulamList.find(
      (kulam) => kulam.id === this.signupForm.get("kula_id").value
    );
    return !selectedKulam || !selectedKulam.kula;
  }

  // Enable the clearable symbol in kothram dorpdown
  isKothramDropdownClearable(): boolean {
    const selectedKothram = this.gotramList.find(
      (kothram) => kothram.id === this.signupForm.get("gotra_id").value
    );
    return !selectedKothram || !selectedKothram.gotra;
  }
}
