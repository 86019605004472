<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
<!-- Bulk Message Admin -->
<div class="account-pages my-5 pt-sm-5" *ngIf="userID == 2">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-5">
            <div class="p-2">
              <form
                [formGroup]="bulkMessageAdminForm"
                (ngSubmit)="onSubmitAdminBulkMessageForm()"
              >
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-4">
                        <label class="form-label">Family List</label>

                        <ng-select
                          [items]="familyList"
                          bindLabel="created_by"
                          bindValue="id"
                          formControlName="ref_id"
                          [multiple]="true"
                          [clearable]="!familyListDropdownClearable()"
                          [ngClass]="{
                            'is-invalid': submitted && f.ref_id.errors,
                            'custom-ng-select': true
                          }"
                        ></ng-select>

                        <div
                          *ngIf="submitted && f.ref_id.errors"
                          class="invalid-feedback"
                        >
                          <span *ngIf="f.ref_id.errors.required"
                            >Please select a family details.</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Message</label>

                      <textarea
                        rows="4"
                        cols="50"
                        formControlName="notification_content"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            submitted && f.notification_content.errors
                        }"
                        id="messageContent"
                        placeholder="Message"
                      ></textarea>

                      <div
                        *ngIf="submitted && f.notification_content.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.notification_content.errors.required">
                          Message is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 d-grid">
                  <button class="btn btn-success btn-rounded" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>

<div class="container-fluid" *ngIf="userID === '2'">
  <h5 class="messageheading">Message History</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterFamilyMessageHistoryList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Family Name</th>
                  <th class="align-middle">Notification Content</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredMessageHistoryFamilyList"
                  id="{{ item.name}}"
                >
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.notification_content }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Bulk Message Super Admin -->
<div class="account-pages my-5 pt-sm-5" *ngIf="userID == 3">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-5">
            <div class="p-2">
              <form
                [formGroup]="bulkMessageSuperAdminForm"
                (ngSubmit)="onSubmitSuperAdminBulkForm()"
              >
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-4">
                        <label class="form-label">Temple List</label>

                        <ng-select
                          [items]="templeList"
                          bindLabel="temple"
                          bindValue="id"
                          formControlName="ref_id"
                          [multiple]="true"
                          [clearable]="!templeListDropdownClearable()"
                          [ngClass]="{
                            'is-invalid': submitted && f1.ref_id.errors,
                            'custom-ng-select': true
                          }"
                        ></ng-select>

                        <div
                          *ngIf="submitted && f1.ref_id.errors"
                          class="invalid-feedback"
                        >
                          <span *ngIf="f1.ref_id.errors.required"
                            >Please select a temple list details
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Message</label>

                      <textarea
                        rows="4"
                        cols="50"
                        formControlName="notification_content"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            submitted && f1.notification_content.errors
                        }"
                        id="messageContent"
                        placeholder="Message"
                      ></textarea>

                      <div
                        *ngIf="submitted && f1.notification_content.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f1.notification_content.errors.required">
                          Message is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 d-grid">
                  <button class="btn btn-success btn-rounded" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>

<div class="container-fluid" *ngIf="userID === '3'">
  <h5 class="messageheading">Message History</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTermTempleHistory"
                    (input)="filterTempleMessageHistoryList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Temple Name</th>
                  <th class="align-middle">Notification Content</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredMessageHistoryTempleList"
                  id="{{ item.temple }}"
                >
                  <td>
                    {{ item.temple }}
                  </td>
                  <td>
                    {{ item.notification_content }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
