import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";
import Swal from "sweetalert2";
import { SharedValueService } from "../services/share-values.service";

@Component({
  selector: "app-bulk-message",
  templateUrl: "./bulk-message.component.html",
  styleUrl: "./bulk-message.component.scss",
})
export class BulkMessageComponent {
  //Below all functionality are added by Giriprasath R

  familyList: any[] = []; // storing the Familylist in the array
  templeList: any[] = []; // storing the templelist in the array
  bulkMessageForm: FormGroup;
  submitted = false;
  userID: any; // storing the sessionStorage userType value in this variable

  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService,
    private router: Router,
    private sharedValueService: SharedValueService
  ) {}

  ngOnInit() {
    this.userId();
    if (this.userID == 2) {
      this.getFamily();
    } else if (this.userID == 3) {
      this.getTempleList();
    }
    this.bulkMessageForm = this.formBuilder.group({
      source_family_id: ["", Validators.required],
      target_family_id: ["", Validators.required],
      message: ["", Validators.required],
    });
  }

  // Get the userID from sessionStorage
  userId() {
    this.userID = sessionStorage.getItem("userType");
  }

  //get the Familylist value for the field
  getFamily() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getFamilyList(`${NetworkService.getFamilyList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.familyList = response.data.familyList;
      });
  }

  //get the Temple list value for the table
  getTempleList() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getTempleList(`${NetworkService.getTempleList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.templeList = response.data.temples;
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.bulkMessageForm.controls;
  }

  /**
   * On submit form
   */

  onSubmit() {
    if (this.bulkMessageForm.invalid) {
      this.submitted = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else if (
      this.bulkMessageForm.value.source_family_id ===
      this.bulkMessageForm.value.target_family_id
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire(
        "Source family and Destination family value must be different."
      );
    } else {
      var postmergeFamilyForm: FormGroup;
      // postmergeFamilyForm = this.formBuilder.group({
      //   source_family_id: this.mergeFamilyForm.value.source_family_id,
      //   target_family_id: this.mergeFamilyForm.value.target_family_id,
      // });
      console.log(postmergeFamilyForm);
      //post the merge family details form value to the DB
      this.apiServices
        .addMergeFamily(postmergeFamilyForm.value)
        .subscribe((response) => {
          if (response.error == false) {
            //send the target_family_id value to the viewfamilydetails component
            this.sharedValueService.familyTreeIdValue =
              this.bulkMessageForm.value.target_family_id;
            this.bulkMessageForm.reset();
          }
        });
    }
  }

  // Enable the clearable symbol in source family dorpdown
  sourceFamilyDropdownClearable(): boolean {
    const sourceFamily = this.familyList.find(
      (source_family) =>
        source_family.id === this.bulkMessageForm.get("source_family_id").value
    );
    return !sourceFamily || !sourceFamily.created_by;
  }

  // // Enable the clearable symbol in destination family dorpdown
  destinationFamilyDropdownClearable(): boolean {
    const destinationFamily = this.familyList.find(
      (destination_family) =>
        destination_family.id ===
        this.bulkMessageForm.get("target_family_id").value
    );
    return !destinationFamily || !destinationFamily.created_by;
  }
}
