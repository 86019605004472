import { Component } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import Swal from "sweetalert2";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";

@Component({
  selector: "app-kothram",
  templateUrl: "./kothram.component.html",
  styleUrls: ["./kothram.component.scss"],
  providers: [DecimalPipe],
})
export class KothramComponent {
  //Below all functionality are added by Giriprasath R

  kulamList: any[] = []; // storing the kulamlist in the array
  kothramList: any[] = []; // storing the kothramlist in the array
  KothramForm: FormGroup;
  submitted = false;

  filteredKothramList: any[] = []; // Filtered kothramList to be displayed
  searchTerm: string = ""; // Search term entered by the user

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiServices: ApiService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getkulam();
    this.getkothram();
  }

  initForm() {
    //Form Validations
    this.KothramForm = this.formBuilder.group({
      id: [""],
      gotra: ["", Validators.required],
      kula_id: ["", Validators.required],
      master_type: ["gotra"], //this value is used in delete api for deleting the data in table
    });
  }

  // Function to filter kothramList based on search term
  filterKothramList() {
    if (this.searchTerm.trim() === "") {
      this.filteredKothramList = this.kothramList;
    } else {
      this.filteredKothramList = this.kothramList.filter((item) =>
        item.gotra.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  //get the Kulamlist value for the field
  getkulam() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getKulamList(`${NetworkService.getKulam()}?_=${timestamp}`)
      .subscribe((response) => {
        this.kulamList = response.data.kulas;
      });
  }

  //get the  Kothramlist value for the field
  getkothram() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getGotram(`${NetworkService.getGotram()}?_=${timestamp}`)
      .subscribe((response) => {
        this.kothramList = response.data.gotras;
        this.filterKothramList();
      });
  }

  // submit the Gothram field dats
  onSubmit() {
    this.submitted = true;
    if (this.KothramForm.invalid) {
      return;
    } else {
      if (
        this.KothramForm.value.id == "" ||
        this.KothramForm.value.id == null
      ) {
        var addKothramForm: FormGroup;
        addKothramForm = this.formBuilder.group({
          gotra: this.KothramForm.value.gotra,
          kula_id: this.KothramForm.value.kula_id,
        });

        //Post the kothram data to the DB
        this.apiServices
          .addGotram(addKothramForm.value)
          .subscribe((response) => {
            if (response.error == false) {
              this.KothramForm.reset();
              this.getkothram();
            }
          });
      } else {
        var updateKothramForm: FormGroup;
        updateKothramForm = this.formBuilder.group({
          gotra: this.KothramForm.value.gotra,
          id: this.KothramForm.value.id,
          kula_id: this.KothramForm.value.kula_id,
        });

        //Update the Kothram data to the DB
        this.apiServices
          .updateKothram(updateKothramForm.value)
          .subscribe((response) => {
            if (response.error == false) {
              this.KothramForm.reset();
              this.getkothram();
            }
          });
      }
    }
  }

  // Delete Data
  delete(_item: any) {
    // Populate the form with the selected row data
    this.KothramForm.patchValue({
      id: _item.id,
      gotra: _item.gotra,
    });
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          var deleteKothramForm: FormGroup;
          deleteKothramForm = this.formBuilder.group({
            id: this.KothramForm.value.id,
            master_type: "gotra",
          });

          // delete the table data of kothram
          this.apiServices
            .deleteMaster(deleteKothramForm.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.KothramForm.reset();
                this.getkothram();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.KothramForm.controls;
  }

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
  }

  /**
   * Open Edit modal
   * @param content modal content
   */
  editDataGet(_item: any, content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
    var modelTitle = document.querySelector(".modal-title") as HTMLAreaElement;
    modelTitle.innerHTML = "Edit Order";
    var updateBtn = document.getElementById("add-btn") as HTMLAreaElement;
    updateBtn.innerHTML = "Update";

    // Populate the form with the selected row data
    this.KothramForm.patchValue({
      id: _item.id,
      kula_id: _item.kula_id,
      gotra: _item.gotra,
    });
  }

  // close the form and reset the form field value is empty
  closeForm() {
    this.KothramForm.reset();
  }

  // Enable the clearable symbol in kulam dorpdown
  isKulamDropdownClearable(): boolean {
    const selectedKulam = this.kulamList.find(
      (kulam) => kulam.id === this.KothramForm.get("kula_id").value
    );
    return !selectedKulam || !selectedKulam.kula;
  }
}
