import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { SharedValueService } from "../services/share-values.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false; /*  */

  password: string;
  passwordVisible: boolean = false;
  customerDetails: any[] = [];
  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private sharedValueService: SharedValueService
  ) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      //login api
      this.apiService.login(this.loginForm.value).subscribe((response) => {
        if (response.error == false) {
          sessionStorage.setItem("accessToken", response.data.token);
          sessionStorage.setItem("userId", response.data.user.id);
          sessionStorage.setItem("userType", response.data.user.role_id);
          sessionStorage.setItem("userName", response.data.user.name);
          sessionStorage.setItem("userEmail", response.data.user.email);
          if (response.data.user.role_id == 1) {
            this.customerDetails.push(response.data.user.name);
            this.customerDetails.push(response.data.user.father_name);
            this.customerDetails.push(response.data.user.gender);
            this.customerDetails.push(response.data.user.dob);
            this.customerDetails.push(response.data.user.phone_number);
            this.sharedValueService.customerDetailsValue = this.customerDetails;
            this.router.navigate(["/getfamily"]);
          } else {
            this.router.navigate(["/kulam"]);
          }
        }
      });
    }
  }

  //Added by Giriprasath R
  //login page passwordVisibility
  passwordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    const passwordInput = document.getElementById(
      "password"
    ) as HTMLInputElement;
    if (this.passwordVisible) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }
}
