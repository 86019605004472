import { Injectable } from "@angular/core";
import { ApiCallService } from "./apiCall.service";
import { NetworkService } from "./network.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private api: ApiCallService) {}

  login(login_data: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.login(), login_data);
  }

  getKulamList(url: string) {
    return this.api.getApiCallNoAuth(url);
  }

  //added by Giriprasath

  register(register_data: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.register(), register_data);
  }

  addAdmin(admin_data: FormData) {
    return this.api.postApiCallAuth(NetworkService.addAdmin(), admin_data);
  }

  addKulam(kulam_data: FormData) {
    return this.api.postApiCallAuth(NetworkService.addKulam(), kulam_data);
  }

  addGotram(gotram_data: FormData) {
    return this.api.postApiCallAuth(NetworkService.addGotram(), gotram_data);
  }

  addTemple(temple_data: FormData) {
    return this.api.postApiCallAuth(NetworkService.addTemple(), temple_data);
  }

  addFatherMother(father_mother_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.addFatherMother(),
      father_mother_data
    );
  }

  addMomFatherMother(mom_father_mother_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.addMomFatherMother(),
      mom_father_mother_data
    );
  }

  addDadFatherMother(dad_father_mother_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.addDadFatherMother(),
      dad_father_mother_data
    );
  }

  addFamilyDetails(add_family_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.addFamilyDetails(),
      add_family_data
    );
  }

  addMergeFamily(merge_family_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.addMergeFamily(),
      merge_family_data
    );
  }

  notification(notification_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.notification(),
      notification_data
    );
  }

  updateAdmin(updateAdmin_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.updateAdmin(),
      updateAdmin_data
    );
  }

  updateKulam(updateKulam_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.updateKulam(),
      updateKulam_data
    );
  }

  updateKothram(updatekothram_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.updateKothram(),
      updatekothram_data
    );
  }

  updateTemple(updateTemple_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.updateTemple(),
      updateTemple_data
    );
  }

  updateFamily(updateFamily_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.updateFamily(),
      updateFamily_data
    );
  }

  getUnApprovedKulamList(url: string) {
    return this.api.getApiCallAuth(url);
  }
  getGotramById(kulam_id: string) {
    return this.api.getApiCallNoAuth(NetworkService.getGotramById() + kulam_id);
  }

  getAdminList(url: string) {
    return this.api.getApiCallAuth(url);
  }

  getGotram(url: string) {
    return this.api.getApiCallNoAuth(url);
  }

  getUnApprovedGotram(url: string) {
    return this.api.getApiCallAuth(url);
  }

  getTempleById(city_id: string) {
    return this.api.getApiCallNoAuth(NetworkService.getTempleById() + city_id);
  }

  getTempleList(url: string) {
    return this.api.getApiCallNoAuth(url);
  }

  getUnApprovedTempleList(url: string) {
    return this.api.getApiCallAuth(url);
  }

  getCountryList(url: string) {
    return this.api.getApiCallNoAuth(url);
  }

  getStateList(country_id: string) {
    return this.api.getApiCallNoAuth(NetworkService.getState() + country_id);
  }

  getCityList(state_id: string) {
    return this.api.getApiCallNoAuth(NetworkService.getCity() + state_id);
  }

  getFamilyList(url: string) {
    return this.api.getApiCallAuth(url);
  }

  getFamilyTree(family_tree_id: string) {
    return this.api.getApiCallAuth(
      NetworkService.getFamilyTree() + family_tree_id
    );
  }

  getFamilyListByTempleId(temple_id: string) {
    return this.api.getApiCallAuth(
      NetworkService.getFamilyListByTempleId() + temple_id
    );
  }

  getPendingFamilyApprovals(url: string) {
    return this.api.getApiCallAuth(url);
  }

  getFamiliesByTemple(url: string) {
    return this.api.getApiCallNoAuth(NetworkService.getFamiliesByTemple());
  }

  getExportData(family_tree_id: any) {
    return this.api.downloadFile(NetworkService.getExportData(family_tree_id));
  }

  getExportTempleData(temple_id: any) {
    return this.api.downloadFile(NetworkService.getExportTempleData(temple_id));
  }

  getMessageHistory(url: string) {
    return this.api.getApiCallAuth(url);
  }

  createKulam(kula: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.createKulam(), kula);
  }

  createKothram(kothram: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.createKothram(), kothram);
  }

  createTemple(temple: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.createtemple(), temple);
  }

  approveRejectFamily(approve_reject_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.approveRejectFamily(),
      approve_reject_data
    );
  }

  sendOTP(otp_data: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.sendOtp(), otp_data);
  }

  verifyOtp(validate_otp_data: FormData) {
    return this.api.postApiCallNoAuth(
      NetworkService.verifyOtp(),
      validate_otp_data
    );
  }

  deleteMaster(delete_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.deleteMasterData(),
      delete_data
    );
  }

  deleteAdmin(delete_admin_data: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.deleteAdmin(),
      delete_admin_data
    );
  }

  deleteFamily(delete_family_date: FormData) {
    return this.api.postApiCallAuth(
      NetworkService.deleteFamily(),
      delete_family_date
    );
  }
}
