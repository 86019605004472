<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6 text-center text-sm-start">
        {{ year }} © JanaVirucham.
      </div>
      <div class="col-12 col-sm-6 text-center text-sm-end">
        <!-- For Smaller Screen -->
        <div class="d-inline-block d-sm-none">Design & Develop by Ero Edge</div>
        <!-- For Larger Screen -->
        <div class="d-none d-sm-inline-block">Design & Develop by Ero Edge</div>
      </div>
    </div>
  </div>
</footer>
