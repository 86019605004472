<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<div class="container-fluid" *ngIf="adminList.length >= 1">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterAdminList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button
                  type="button"
                  class="btn btn-success btn-rounded mb-2 me-2"
                  (click)="openModal(content)"
                >
                  <i class="mdi mdi-plus mr-1"></i> Add
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Email</th>
                  <th class="align-middle">Kothram</th>
                  <th class="align-middle">Templ Name</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredAdminList"
                  id="{{ item.users }}"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.gotra }}</td>
                  <td>{{ item.temple }}</td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="editDataGet(item, content)"
                        ><i class="mdi mdi-pencil font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-danger"
                        (click)="delete(item)"
                        ><i class="mdi mdi-delete font-size-18"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Admin Form Create Model -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add Admin Details</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="[modal.dismiss('Cross click'), closeForm()]"
    ></button>
  </div>
  <form
    (ngSubmit)="[onSubmit(), modal.close('Close click')]"
    [formGroup]="adminForm"
  >
    <div class="modal-body">
      <div class="row">
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>

          <input
            type="text"
            formControlName="name"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            id="name"
            placeholder="User Name"
          />

          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
        </div>

        <div class="mb-3">
          <label for="email" class="form-label">Email</label>

          <input
            type="email"
            formControlName="email"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            id="email"
            placeholder="Email"
          />

          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">
              Email must be a valid email address
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">Country</label>

            <ng-select
              [items]="countryList"
              bindLabel="country"
              bindValue="id"
              formControlName="country_id"
              [multiple]="false"
              [clearable]="!isCountryDropdownClearable()"
              (change)="getState($event)"
              [ngClass]="{ 'is-invalid': submitted && f.country_id.errors }"
            ></ng-select>

            <div
              *ngIf="submitted && f.country_id.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.country_id.errors.required"
                >Please select a country.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">State</label>

            <ng-select
              [items]="statesList"
              bindLabel="state_name"
              bindValue="id"
              formControlName="state_id"
              [multiple]="false"
              [clearable]="!isStateDropdownClearable()"
              (change)="getCity($event)"
              [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
            ></ng-select>

            <div
              *ngIf="submitted && f.state_id.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.state_id.errors.required"
                >Please select a state.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">City</label>

            <ng-select
              [items]="cityList"
              bindLabel="city_name"
              bindValue="id"
              formControlName="city_id"
              [multiple]="false"
              [clearable]="!isCityDropdownClearable()"
              (change)="getTemple($event)"
              [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
            ></ng-select>

            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
              <span *ngIf="f.city_id.errors.required"
                >Please select a city.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label">Temple</label>

            <ng-select
              [items]="templeList"
              bindLabel="temple"
              bindValue="id"
              formControlName="temple_id"
              [multiple]="false"
              [clearable]="!isTempleDropdownClearable()"
              [ngClass]="{ 'is-invalid': submitted && f.temple_id.errors }"
            ></ng-select>

            <div
              *ngIf="submitted && f.temple_id.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.temple_id.errors.required"
                >Please select a temple.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="[modal.close('Close click'), closeForm()]"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn">Add</button>
      </div>
    </div>
  </form>
</ng-template>
<!--End Modal -->

<!-- No data has been found the below div will shown on the component -->
<div class="account-pages my-5 pt-5" *ngIf="adminList.length < 1">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <div class="mt-5 text-center">
            <h4 class="text-primary">
              Sorry, No admin details has been assigned.
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
