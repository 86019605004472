export class Consts {
  static Version = "0.0.1";
  static Production = false;

  static SERVER_URL_TEST = "https://devapi.janavirutcham.in";
  static SERVER_URL_PROD = "http://13.49.145.206/";

  static URL(): string {
    if (this.Production) return this.SERVER_URL_PROD;
    else return this.SERVER_URL_TEST;
  }
}
