import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SharedValueService {
  private STORAGE_KEY = "familyTreeId";
  private LOGIN_VALUE = "customerDetails";
  constructor() {}

  // This SharedValueService component is created by Giriprasath R

  // call the set familyTreeIdValue function for get the values in set function
  get familyTreeIdValue(): string {
    return localStorage.getItem(this.STORAGE_KEY);
  }

  // set familyTreeIdValue function is for set the value from the component which are used this service in their required component
  set familyTreeIdValue(value: string) {
    localStorage.setItem(this.STORAGE_KEY, value);
  }

  // call the set customerDetailsValue function for get the values in set function
  get customerDetailsValue(): string[] {
    const storedValue = localStorage.getItem(this.LOGIN_VALUE);
    return storedValue ? JSON.parse(storedValue) : [];
  }

  // set customerDetailsValue function is for set the value from the component which are used this service in their required component
  set customerDetailsValue(value: string[]) {
    localStorage.setItem(this.LOGIN_VALUE, JSON.stringify(value));
  }
}
