import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-kulam",
  templateUrl: "./kulam.component.html",
  styleUrls: ["./kulam.component.scss"],
  providers: [DecimalPipe],
})
export class KulamComponent implements OnInit {
  //Below all functionality are added by Giriprasath R

  kulamList: any[] = []; // storing the kulamlist in the array
  kulamForm: FormGroup;
  submitted = false;

  filteredKulamList: any[] = []; // Filtered kulamList to be displayed
  searchTerm: string = ""; // Search term entered by the user

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiServices: ApiService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getKulam();
  }

  initForm() {
    this.submitted = false;
    //Form Validations
    this.kulamForm = this.formBuilder.group({
      id: [""],
      kula: ["", Validators.required],
      master_type: ["kula"], //this value is used in delete api for deleting the data in table
    });
  }

  // Function to filter kulamList based on search term
  filterKulamList() {
    if (this.searchTerm.trim() === "") {
      this.filteredKulamList = this.kulamList;
    } else {
      this.filteredKulamList = this.kulamList.filter((item) =>
        item.kula.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  //get the Kulamlist value for the field
  getKulam() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getKulamList(`${NetworkService.getKulam()}?_=${timestamp}`)
      .subscribe((response) => {
        this.kulamList = response.data.kulas;
        this.filterKulamList();
      });
  }

  // Delete Data
  delete(_item: any) {
    // Populate the form with the selected row data
    this.kulamForm.patchValue({
      id: _item.id,
      kula: _item.kula,
    });
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          var deleteKulamForm: FormGroup;
          deleteKulamForm = this.formBuilder.group({
            id: this.kulamForm.value.id,
            master_type: "kula",
          });
          // delete the table data of kulam
          this.apiServices
            .deleteMaster(deleteKulamForm.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.kulamForm.reset();
                this.getKulam();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your data has been deleted.",
                  "success"
                );
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your date is safe :)",
            "error"
          );
        }
      });
  }

  //submit the kulam form
  onSubmit() {
    this.submitted = true;
    if (this.kulamForm.invalid) {
      return;
    } else {
      if (this.kulamForm.value.id == "" || this.kulamForm.value.id == null) {
        var addKulamForm: FormGroup;
        addKulamForm = this.formBuilder.group({
          kula: this.kulamForm.value.kula,
        });

        //Post the kulam data to the DB
        this.apiServices.addKulam(addKulamForm.value).subscribe((response) => {
          if (response.error == false) {
            this.kulamForm.reset();
            this.getKulam();
          }
        });
      } else {
        var updateKulamForm: FormGroup;
        updateKulamForm = this.formBuilder.group({
          kula: this.kulamForm.value.kula,
          id: this.kulamForm.value.id,
        });

        //Update the Kulam data to the DB
        this.apiServices
          .updateKulam(updateKulamForm.value)
          .subscribe((response) => {
            if (response.error == false) {
              this.kulamForm.reset();
              this.getKulam();
            }
          });
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.kulamForm.controls;
  }

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
  }

  /**
   * edit the kulam data
   * @param content modal content
   */
  editDataGet(_item: any, content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
    var modelTitle = document.querySelector(".modal-title") as HTMLAreaElement;
    modelTitle.innerHTML = "Edit Kulam";
    var updateBtn = document.getElementById("add-btn") as HTMLAreaElement;
    updateBtn.innerHTML = "Update";

    // Populate the form with the selected row data
    this.kulamForm.patchValue({
      id: _item.id,
      kula: _item.kula,
    });
  }

  // close the form and reset the form field value is empty
  closeForm() {
    this.kulamForm.reset();
  }
}
