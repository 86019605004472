import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";

@Component({
  selector: "app-bulk-message",
  templateUrl: "./bulk-message.component.html",
  styleUrl: "./bulk-message.component.scss",
})
export class BulkMessageComponent {
  //Below all functionality are added by Giriprasath R

  messageHistory: any[] = []; //storing the messageHistory in the array
  familyList: any[] = []; // storing the Familylist in the array
  templeList: any[] = []; // storing the templelist in the array
  bulkMessageAdminForm: FormGroup;
  bulkMessageSuperAdminForm: FormGroup;
  submitted = false;
  userID: any; // storing the sessionStorage userType value in this variable

  filteredMessageHistoryFamilyList: any[] = []; // Filtered MessageHistoryList to be displayed
  filteredMessageHistoryTempleList: any[] = []; // Filtered MessageHistoryList to be displayed
  searchTerm: string = ""; // Search term entered by the user in message history family list
  searchTermTempleHistory: string = ""; // Search term entered by the user in message history family list

  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService
  ) {}

  ngOnInit() {
    this.userId();
    this.getMessageHistory();
    if (this.userID == 2) {
      this.getFamily();
    } else if (this.userID == 3) {
      this.getTempleList();
    }

    // Form group for bulk message admin form
    this.bulkMessageAdminForm = this.formBuilder.group({
      ref_id: [[], Validators.required],
      ref_type: ["family", Validators.required],
      notification_content: ["", Validators.required],
    });

    // Form group for bulk message super admin form
    this.bulkMessageSuperAdminForm = this.formBuilder.group({
      ref_id: [[], Validators.required],
      ref_type: ["temple", Validators.required],
      notification_content: ["", Validators.required],
    });
  }

  // Get the userID from sessionStorage
  userId() {
    this.userID = sessionStorage.getItem("userType");
  }

  // Function to filter message history for family list based on search term
  filterFamilyMessageHistoryList() {
    if (this.searchTerm.trim() === "") {
      this.filteredMessageHistoryFamilyList = this.messageHistory;
    } else {
      this.filteredMessageHistoryFamilyList = this.messageHistory.filter(
        (item) =>
          item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  // Function to filter message history for temple list based on search term
  filterTempleMessageHistoryList() {
    if (this.searchTermTempleHistory.trim() === "") {
      this.filteredMessageHistoryTempleList = this.messageHistory;
    } else {
      this.filteredMessageHistoryTempleList = this.messageHistory.filter(
        (item) =>
          item.temple
            .toLowerCase()
            .includes(this.searchTermTempleHistory.toLowerCase())
      );
    }
  }

  //get the message history value for the table
  getMessageHistory() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getMessageHistory(`${NetworkService.getMessageHistory()}?_=${timestamp}`)
      .subscribe((response) => {
        this.messageHistory = response.data;
        this.filterFamilyMessageHistoryList();
        this.filterTempleMessageHistoryList();
      });
  }

  //get the family list value for the field
  getFamily() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getFamilyList(`${NetworkService.getFamilyList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.familyList = response.data.familyList;
      });
  }

  //get the temple list value for the table
  getTempleList() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getTempleList(`${NetworkService.getTempleList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.templeList = response.data.temples;
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.bulkMessageAdminForm.controls;
  }
  get f1() {
    return this.bulkMessageSuperAdminForm.controls;
  }

  /**
   *  onSubmitAdminBulkMessageForm
   */

  onSubmitAdminBulkMessageForm() {
    if (this.bulkMessageAdminForm.invalid) {
      this.submitted = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else {
      //post the bulk message details form value to the DB
      this.apiServices
        .notification(this.bulkMessageAdminForm.value)
        .subscribe((response) => {
          if (response.error == false) {
            this.bulkMessageAdminForm.reset();
            this.getMessageHistory();
          }
        });
    }
  }

  /**
   *  onSubmitAdminBulkMessageForm
   */

  onSubmitSuperAdminBulkForm() {
    if (this.bulkMessageSuperAdminForm.invalid) {
      this.submitted = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else {
      //post the bulk message details form value to the DB
      this.apiServices
        .notification(this.bulkMessageSuperAdminForm.value)
        .subscribe((response) => {
          if (response.error == false) {
            this.bulkMessageSuperAdminForm.reset();
            this.getMessageHistory();
          }
        });
    }
  }

  // Enable the clearable symbol in family list dorpdown
  familyListDropdownClearable(): boolean {
    const selected_family_list = this.familyList.find(
      (selected_family) =>
        selected_family.id === this.bulkMessageAdminForm.get("ref_id").value
    );
    return !selected_family_list || !selected_family_list.created_by;
  }

  // // Enable the clearable symbol in temple list dorpdown
  templeListDropdownClearable(): boolean {
    const selected_temple_list = this.templeList.find(
      (selected_temple) =>
        selected_temple.id ===
        this.bulkMessageSuperAdminForm.get("ref_id").value
    );
    return !selected_temple_list || !selected_temple_list.temple;
  }
}
