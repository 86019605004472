<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5 text-muted">
          <a routerLink="/" class="d-block auth-logo">
            <img
              src="assets/images/logo-dark.png"
              alt=""
              height="20"
              class="auth-logo-dark mx-auto"
            />
            <img
              src="assets/images/logo-light.png"
              alt=""
              height="20"
              class="auth-logo-light mx-auto"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body">
            <div class="p-2">
              <div class="text-center">
                <div class="avatar-md mx-auto">
                  <div class="avatar-title rounded-circle bg-light">
                    <i class="bx bxs-envelope h1 mb-0 text-primary"></i>
                  </div>
                </div>
                <form [formGroup]="otpForm" (ngSubmit)="onSubmit()">
                  <div class="p-2 mt-4">
                    <h4>Verify your email</h4>
                    <p class="mb-5">
                      Please enter the 6 digit code sent to
                      <span class="fw-semibold">{{ emailAddress }}</span>
                    </p>
                    <ng-otp-input
                      (onInputChange)="onOtpChange($event)"
                      [config]="config"
                    ></ng-otp-input>
                    <div class="mt-4">
                      <button class="btn btn-success w-md" type="submit">
                        Confirm
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <p>
            Did't receive a code ?
            <a
              href="javascript: void(0);"
              class="fw-medium text-primary"
              (click)="getOtp()"
            >
              Resend
            </a>
          </p>
          <p>
            © {{ year }} JanaVirucham. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Ero Edge
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
