import { MenuItem } from "./menu.model";

export const SUPERADMINMENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.KULAM.TEXT",
    icon: "fas fa-om",
    link: "/kulam",
  },
  {
    id: 2,
    label: "MENUITEMS.KOTHRAM.TEXT",
    icon: "fas fa-gopuram",
    link: "/kothram",
  },
  {
    id: 3,
    label: "MENUITEMS.TEMPLELIST.TEXT",
    icon: "bx bx-list-plus",
    link: "/addtemple",
  },
  {
    id: 4,
    label: "MENUITEMS.ADMINLIST.TEXT",
    icon: "bx bxs-user-detail",
    link: "/adminlist",
  },
  {
    id: 5,
    label: "MENUITEMS.GETFAMILYLIST.TEXT",
    icon: "fas fa-users",
    link: "/getfamily",
  },
  {
    id: 6,
    label: "MENUITEMS.BULKMESSAGE.TEXT",
    icon: "bx bx-chat",
    link: "/bulkMessage",
  },
];

export const ADMINMENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.KULAM.TEXT",
    icon: "fas fa-om",
    link: "/kulam",
  },
  {
    id: 2,
    label: "MENUITEMS.KOTHRAM.TEXT",
    icon: "fas fa-gopuram",
    link: "/kothram",
  },
  {
    id: 3,
    label: "MENUITEMS.GETFAMILYLIST.TEXT",
    icon: "fas fa-users",
    link: "/getfamily",
  },
  {
    id: 4,
    label: "MENUITEMS.APPROVEPENDINGLIST.TEXT",
    icon: "bx bx-list-check",
    link: "/approvePendingList",
  },
  {
    id: 5,
    label: "MENUITEMS.MERGEFAMILY.TEXT",
    icon: "bx bx-git-merge",
    link: "/mergeFamily",
  },
  {
    id: 6,
    label: "MENUITEMS.BULKMESSAGE.TEXT",
    icon: "bx bx-chat",
    link: "/bulkMessage",
  },
];

export const CUSTOMERMENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.GETFAMILYLIST.TEXT",
    icon: "fas fa-users",
    link: "/getfamily",
  },
];
