import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";
import Swal from "sweetalert2";
import { SharedValueService } from "../services/share-values.service";

@Component({
  selector: "app-mergefamily",
  templateUrl: "./mergefamily.component.html",
  styleUrl: "./mergefamily.component.scss",
})
export class MergefamilyComponent implements OnInit {
  //Below all functionality are added by Giriprasath R

  familyList: any[] = []; // storing the Familylist in the array
  mergeFamilyForm: FormGroup;
  submitted = false;

  // tslint:disable-next-line: max-line-length
  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService,
    private router: Router,
    private sharedValueService: SharedValueService
  ) {}

  ngOnInit() {
    this.getFamily();
    this.mergeFamilyForm = this.formBuilder.group({
      source_family_id: ["", Validators.required],
      target_family_id: ["", Validators.required],
    });
  }

  //get the Familylist value for the field
  getFamily() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getFamilyList(`${NetworkService.getFamilyList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.familyList = response.data.familyList;
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.mergeFamilyForm.controls;
  }

  /**
   * On submit form
   */

  onSubmit() {
    if (this.mergeFamilyForm.invalid) {
      this.submitted = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else if (
      this.mergeFamilyForm.value.source_family_id ===
      this.mergeFamilyForm.value.target_family_id
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire(
        "Source family and Destination family value must be different."
      );
    } else {
      var postmergeFamilyForm: FormGroup;
      postmergeFamilyForm = this.formBuilder.group({
        source_family_id: this.mergeFamilyForm.value.source_family_id,
        target_family_id: this.mergeFamilyForm.value.target_family_id,
      });
      console.log(postmergeFamilyForm);
      //post the merge family details form value to the DB
      this.apiServices
        .addMergeFamily(postmergeFamilyForm.value)
        .subscribe((response) => {
          if (response.error == false) {
            //send the target_family_id value to the viewfamilydetails component
            this.sharedValueService.familyTreeIdValue =
              this.mergeFamilyForm.value.target_family_id;
            this.mergeFamilyForm.reset();
            this.router.navigate(["/viewfamilydetails"]);
          }
        });
    }
  }

  // Enable the clearable symbol in source family dorpdown
  sourceFamilyDropdownClearable(): boolean {
    const sourceFamily = this.familyList.find(
      (source_family) =>
        source_family.id === this.mergeFamilyForm.get("source_family_id").value
    );
    return !sourceFamily || !sourceFamily.created_by;
  }

  // // Enable the clearable symbol in destination family dorpdown
  destinationFamilyDropdownClearable(): boolean {
    const destinationFamily = this.familyList.find(
      (destination_family) =>
        destination_family.id ===
        this.mergeFamilyForm.get("target_family_id").value
    );
    return !destinationFamily || !destinationFamily.created_by;
  }
}
