import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-otpverify",
  templateUrl: "./otpverify.component.html",
  styleUrl: "./otpverify.component.scss",
})
export class OtpverifyComponent {
  //Below all functionality are added by Giriprasath R

  otpForm: FormGroup;
  otpVerifyForm: FormGroup;
  signupFormValue: FormGroup;
  submitted = false;

  emailAddress: string;
  passwordValue: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    // For getting the email value and password from signupComponent and passwordresetComponent based on the flow
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      email: string;
      password: string;
    };
    this.emailAddress = state.email;
    this.passwordValue = state.password;

    if (this.emailAddress != "" && this.passwordValue != "") {
      this.getOtp();
    }
  }

  //otp input field part

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "50px",
      height: "50px",
    },
    formControlName: "otp",
    id: "otp",
    class: "form-control",
  };

  // set the currenr year
  year: number = new Date().getFullYear();

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.otpForm = this.formBuilder.group({
      otp: [""],
    });
  }

  // Getting the otp value in the input field and stored into the otpForm
  onOtpChange(_otp) {
    this.otpForm.patchValue({
      otp: _otp,
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.otpForm.controls;
  }

  // Get otp api calls
  getOtp() {
    this.signupFormValue = this.formBuilder.group({
      email: this.emailAddress,
    });
    this.apiService
      .sendOTP(this.signupFormValue.value)
      .subscribe((response) => {});
  }

  // Otp form submission function flow
  onSubmit() {
    //THrown an error when the OTP is invalid
    const otpValue = this.otpForm.get("otp").value;
    if (otpValue === "" || otpValue.length < 6) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire("Please enter a valid OTP");
    } else {
      this.submitted = true;
      this.otpVerifyForm = this.formBuilder.group({
        email: this.emailAddress,
        password: this.passwordValue,
        otp: this.otpForm.value.otp,
      });

      // Verify otp api calls
      this.apiService
        .verifyOtp(this.otpVerifyForm.value)
        .subscribe((response) => {
          if (response.error == false) {
            this.otpForm.reset();
            this.signupFormValue.reset();
            this.otpVerifyForm.reset();
            this.router.navigate(["/login"]);
          }
        });
    }
  }
}
