<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-5">
            <div class="p-2">
              <form
                [formGroup]="mergeFamilyForm"
                (ngSubmit)="onSubmit()"
              >
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label class="form-label">Source Family</label>

                      <ng-select
                        [items]="familyList"
                        bindLabel="created_by"
                        bindValue="id"
                        formControlName="source_family_id"
                        [multiple]="false"
                        [clearable]="!sourceFamilyDropdownClearable()"
                        [ngClass]="{
                          'is-invalid': submitted && f.source_family_id.errors,
                          'custom-ng-select': true
                        }"
                      ></ng-select>

                      <div
                        *ngIf="submitted && f.source_family_id.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.source_family_id.errors.required"
                          >Please select a source family details.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label class="form-label">Destination Family</label>

                      <ng-select
                        [items]="familyList"
                        bindLabel="created_by"
                        bindValue="id"
                        formControlName="target_family_id"
                        [multiple]="false"
                        [clearable]="!destinationFamilyDropdownClearable()"
                        [ngClass]="{
                          'is-invalid': submitted && f.target_family_id.errors,
                          'custom-ng-select': true
                        }"
                      ></ng-select>

                      <div
                        *ngIf="submitted && f.target_family_id.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.target_family_id.errors.required"
                          >Please select a destination family details
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div class="mt-4 d-grid">
                  <button class="btn btn-success btn-rounded" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
