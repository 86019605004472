import { ApiService } from "../services/api.service";
import { Component } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import FamilyTree from "./familytree";
import { SharedValueService } from "../services/share-values.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-viewfamilydetails",
  templateUrl: "./viewfamilydetails.component.html",
  styleUrl: "./viewfamilydetails.component.scss",
  providers: [DecimalPipe],
})
export class ViewfamilydetailsComponent {
  familyForm: FormGroup;
  updatefamilyForm: FormGroup;
  tree: any;
  selectedNodeDetails: any;
  familyTreeResponse: object[] = []; // storing the Family tree response in the array
  ftResponse: any; // storing the Family tree response in the array
  relationship_Type: string; //storing the relationship value in local
  relationship_type: string; //storing the relationship it's used for passing the value to the DB
  familyId: string; //storing the familytree ID in this variable
  sourceId: number; //storing the Source_ID value in string format to this variable
  divorcedId: string; //storing the divorced_ID value in string format to this variable
  source_ID: any; //storing the node pid,fid,mid value in this variable
  divorced_ID: any; //storing the node relationship value in this variable
  motherId: string; // storing the mid value in this variable for condition statement check
  fatherId: string; // storing the fid value  in this variable for condition statement check
  userID: string; // storing the sessionStorage userType value in this variable

  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService,
    private sharedValueService: SharedValueService
  ) {}

  ngOnInit() {
    // Getting the family tree id value from getFamilyDetailsComponent or mergefamilyComponent by using service file
    this.familyId = this.sharedValueService.familyTreeIdValue;
    // Get the userID from sessionStorage
    this.userID = sessionStorage.getItem("userType");
    this.getFamilyTree();
  }

  //Generate the Family Tree

  initFamilyTree() {
    FamilyTree.templates.hugo = Object.assign({}, FamilyTree.templates.base);
    FamilyTree.templates.hugo.defs = `<clipPath id="hugo_img_0"><rect id="hugo_img_0_stroke" stroke-width="2" stroke="#fff" x="90" y="-5" rx="25" ry="25" width="70" height="70"></rect></clipPath>
        <linearGradient id="hugo_grad_female" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color:#FF8024;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#FF46A3;stop-opacity:1" />
        </linearGradient>
        <linearGradient id="hugo_grad_male" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color:#00D3A5;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#00A7D4;stop-opacity:1" />
        </linearGradient>
        <linearGradient id="hugo_grad" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color:#D0D0D0;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#909090;stop-opacity:1" />
        </linearGradient>
        <g id="hugo_up">
        <circle cx="12" cy="12" r="15" fill="transparent"></circle>
            ${FamilyTree.icon.ft(24, 24, "#fff", 0, 0)}
        </g>
        <g id="hugo_node_menu" style="cursor:pointer;">
            <rect x="0" y="0" fill="transparent" width="22" height="22"></rect>
            <circle cx="11" cy="4" r="2" fill="#ffffff"></circle><circle cx="11" cy="11" r="2" fill="#ffffff"></circle>
            <circle cx="11" cy="18" r="2" fill="#ffffff"></circle>
        </g>
            <style>
               .{randId} .bft-edit-form-header{
                    background: linear-gradient(90deg, #D0D0D0 0%, #909090 100%);
                }
                .{randId}.male .bft-edit-form-header{
                    background: linear-gradient(90deg, #00D3A5 0%, #00A7D4 100%);
                }
                .{randId}.female .bft-edit-form-header{
                    background: linear-gradient(90deg, #FF8024 0%, #FF46A3 100%);
                }  
                .{randId} .bft-img-button{
                    background-color: #909090;
                }      
                .{randId} .bft-img-button:hover{
                    background-color: #D0D0D0;
                }
                .{randId}.male .bft-img-button{
                    background-color: #00A7D4;
                }      
                .{randId}.male .bft-img-button:hover{
                    background-color: #00D3A5;
                }
                .{randId}.female .bft-img-button{
                    background-color: #FF46A3;
                }      
                .{randId}.female .bft-img-button:hover{
                    background-color: #FF8024;
                }
        </style>`;
    FamilyTree.templates.hugo.img_0 = "";
    FamilyTree.templates.hugo.field_0 =
      "<text " +
      FamilyTree.attr.width +
      ' ="230" style="font-size: 18px;font-weight:bold;" fill="#ffffff" x="125" y="50" text-anchor="middle">{val}</text>';
    FamilyTree.templates.hugo.field_1 =
      "<text " +
      FamilyTree.attr.width +
      ' ="230" style="font-size: 14px;" fill="#ffffff" x="125" y="80" text-anchor="middle">{val}</text>';
    FamilyTree.templates.hugo.field_2 =
      "<text " +
      FamilyTree.attr.width +
      ' ="230" style="font-size: 18px;font-weight:bold;" fill="#ffffff" x="125" y="80" text-anchor="middle">{val}</text>';
    FamilyTree.templates.hugo_male = Object.assign(
      {},
      FamilyTree.templates.hugo
    );
    FamilyTree.templates.hugo_male.node =
      '<rect x="0" y="0" height="{h}" width="{w}" stroke-width="1" fill=url(#hugo_grad_male) stroke="#aeaeae" rx="7" ry="7"></rect>';
    FamilyTree.templates.hugo_female = Object.assign(
      {},
      FamilyTree.templates.hugo
    );
    FamilyTree.templates.hugo_female.node =
      '<rect x="0" y="0" height="{h}" width="{w}" stroke-width="1" fill=url(#hugo_grad_female) stroke="#aeaeae" rx="7" ry="7"></rect>';

    FamilyTree.templates.hugo.up =
      '<use x="200" y="10" xlink:href="#hugo_up" />';
    FamilyTree.templates.hugo.nodeMenuButton = `<use x="225" y="10" ${FamilyTree.attr.control_node_menu_id}="{id}" xlink:href="#hugo_node_menu" />`;
    FamilyTree.templates.brother = Object.assign(
      {},
      FamilyTree.templates.mother
    );
    FamilyTree.templates.brother.node =
      '<rect x="0" y="0" height="{h}" width="{w}" style="fill:transparent;height:{h}; width:{w};stroke-width:1;stroke:#FFCA28;rx:5;ry:5;"></rect>' +
      '<text style="font-size: 18px;" fill="#FFCA28" x="240" y="30" text-anchor="end">Add brother</text>' +
      FamilyTree.icon.husband(70, 70, "#FFCA28", 10, 40);

    FamilyTree.templates.sister = Object.assign(
      {},
      FamilyTree.templates.mother
    );
    FamilyTree.templates.sister.node =
      '<rect x="0" y="0" height="{h}" width="{w}" style="fill:transparent;height:{h}; width:{w};stroke-width:1;stroke:#FFCA28;rx:5;ry:5;"></rect>' +
      '<text style="font-size: 18px;" fill="#FFCA28" x="240" y="30" text-anchor="end">Add sister</text>' +
      FamilyTree.icon.wife(70, 70, "#FFCA28", 10, 40);
    const tree = document.getElementById("tree");

    //Added by Giriprasath R
    //Based on the condition required tree functionality is worked
    if ((tree && this.userID == "2") || (tree && this.userID == "3")) {
      var family = new FamilyTree(document.getElementById("tree"), {
        showXScroll: FamilyTree.scroll.visible,
        mouseScrool: FamilyTree.action.scroll,
        mode: "light",
        template: "hugo",
        enableSearch: false,
        nodeTreeMenu: null,
        nodeBinding: {
          field_0: "name",
          field_1: "born",
          // field_2: "phone_number",
        },
        editForm: {
          readOnly: true,
          titleBinding: "name",
          generateElementsFromFields: false,
          addMore: null,
          buttons: {
            edit: {
              hideIfEditMode: true,
              hideIfDetailsMode: false,
            },
            share: null,
            pdf: null,
            remove: null,
          },
          elements: [
            {
              type: "textbox",
              label: "Full Name",
              binding: "name",
              vlidators: { required: "Please fill your name" },
            },
            {
              type: "textbox",
              label: "Mobile Number",
              binding: "phone_number",
            },
            [
              {
                type: "checkbox",
                label: "Is Alive",
                binding: "active",
              },

              {
                type: "date",
                label: "Date Of Birth",
                binding: "born",
                vlidators: { required: "Please fill your dob" },
              },
            ],
          ],
        },
      });

      //Added by Giriprasath R
      //Changing the node color based on the condition
      for (var i = 0; i < this.familyTreeResponse.length; i++) {
        this.ftResponse = this.familyTreeResponse[i];
        if (this.ftResponse.family_captain == "Yes") {
          this.ftResponse.tags = ["Yes"];
        }
      }

      family.load(this.familyTreeResponse);
    } else if (tree) {
      var family = new FamilyTree(document.getElementById("tree"), {
        showXScroll: FamilyTree.scroll.visible,
        mouseScrool: FamilyTree.action.scroll,
        mode: "light",
        template: "hugo",
        enableSearch: false,
        nodeMenu: {
          edit: { text: "Edit" },
          details: { text: "Details" },
        },
        nodeTreeMenu: true,
        nodeBinding: {
          field_0: "name",
          field_1: "born",
          // field_2: "phone_number",
        },
        editForm: {
          titleBinding: "name",
          generateElementsFromFields: false,
          addMore: null,
          saveAndCloseBtn: "Save",
          buttons: {
            edit: {
              icon: FamilyTree.icon.edit(24, 24, "#fff"),
              text: "Edit",
              hideIfEditMode: true,
              hideIfDetailsMode: false,
            },
            share: null,
            pdf: null,
            remove: null,
          },
          elements: [
            {
              type: "textbox",
              label: "Full Name",
              binding: "name",
              vlidators: { required: "Please fill your name" },
            },
            [
              {
                type: "checkbox",
                label: "Is Alive",
                binding: "active",
              },

              {
                type: "date",
                label: "Date Of Birth",
                binding: "born",
                vlidators: { required: "Please fill your dob" },
              },

              {
                type: "textbox",
                label: "Mobile Number",
                binding: "phone_number",
              },
            ],
          ],
        },
      });
      family.on("render-link", function (sender, args) {
        var cnodeData = family.get(args.cnode.id);
        var nodeData = family.get(args.node.id);
        if (
          cnodeData.divorced != undefined &&
          nodeData.divorced != undefined &&
          cnodeData.divorced.includes(args.node.id) &&
          nodeData.divorced.includes(args.cnode.id)
        ) {
          args.html = args.html.replace("path", "path stroke-dasharray='3, 2'");
        }
      });
      family.on("field", function (sender, args) {
        if (args.name == "born") {
          var date = new Date(args.value);
          args.value = date.toLocaleDateString();
        }
      });
      family.onUpdateNode((args) => {
        if (args.addNodesData.length === 0) {
          this.onSubmit(args.updateNodesData[0]);
        }
      });

      // Added by Giriprasath
      // The below code will affect the node ui[The Relationship field will display only for spouse node]
      family.editUI.on("show", function (sender, id) {
        var data: any = family.get(id);
        if (
          data.relationType == "sister" ||
          data.relationType == "brother" ||
          data.relationType == "son" ||
          data.relationType == "daughter" ||
          data.relationType == "mother" ||
          data.relationType == "father"
        ) {
          family.config.editForm.elements[2] = [];
        } else {
          if (data.is_married === 0 || data.pids.length === 0) {
            family.config.editForm.elements[2] = [];
          } else {
            family.config.editForm.elements[2] = {
              type: "select",
              options: [
                { value: "0", text: "Married" },
                { value: "1", text: "Divorced" },
              ],
              label: "Relationship",
              binding: "relationship",
              vlidators: { required: "Please choose the relationship status" },
            };
          }
        }
      });

      family.on("node-tree-menu-show", function (sender, args) {
        var groupNodeIds = [];
        for (var i = args.nodes.length - 1; i >= 0; i--) {
          var node = args.nodes[i];
          if (typeof node.id == "string") {
            if (
              node.id.includes("_ft_daughter") ||
              node.id.includes("_ft_son")
            ) {
              if (!FamilyTree.isNEU(node.stpid)) {
                var gnode = null;
                for (var j = 0; j < args.nodes.length; j++) {
                  if (args.nodes[j].id == node.stpid) {
                    gnode = args.nodes[j];
                    break;
                  }
                }

                if (
                  (typeof gnode.mid == "string" &&
                    gnode.mid.includes("_ft_")) ||
                  (typeof gnode.fid == "string" && gnode.fid.includes("_ft_"))
                ) {
                  args.nodes.splice(i, 1);
                  groupNodeIds.push(node.stpid);
                }
              }
            }
          }
        }
        for (var i = args.nodes.length - 1; i >= 0; i--) {
          var node = args.nodes[i];
          if (groupNodeIds.includes(node.id)) {
            args.nodes.splice(i, 1);
          }
          if (typeof node.id == "string") {
            if (node.id == "_ft_father") {
              node._ft_data.relationType = "father";
              node._ft_data.sourceId = node._ft_childId;
            }
            if (node.id == "_ft_mother") {
              node._ft_data.relationType = "mother";
              node._ft_data.sourceId = node._ft_childId;
            }
            if (node.id.includes("_ft_daughter")) {
              node._ft_data.relationType = "daughter";
            }
            if (node.id.includes("_ft_son")) {
              node._ft_data.relationType = "son";
            }
            if (node.id.includes("_ft_partner")) {
              node._ft_data.relationType = "spouse";
            }
          }
        }
        args.nodes.push({
          id: "_ft_brother",
          mid: args.node.mid,
          fid: args.node.fid,
          sourceId: args.node.id,
          templateName: "brother",
          _ft_method: "addChildNode",
          _ft_data: {
            mid: args.node.mid,
            fid: args.node.fid,
            sourceId: args.node.id,
            relationType: "brother",
            gender: "male",
          },
        });
        args.nodes.push({
          id: "_ft_sister",
          mid: args.node.mid,
          fid: args.node.fid,
          sourceId: args.node.id,
          templateName: "sister",
          _ft_method: "addChildNode",
          _ft_data: {
            mid: args.node.mid,
            fid: args.node.fid,
            sourceId: args.node.id,
            relationType: "sister",
            gender: "female",
          },
        });
      });

      //Added by Giriprasath R
      //Changing the node color based on the condition
      for (var i = 0; i < this.familyTreeResponse.length; i++) {
        this.ftResponse = this.familyTreeResponse[i];
        if (this.ftResponse.family_captain == "Yes") {
          this.ftResponse.tags = ["Yes"];
        }
      }

      family.load(this.familyTreeResponse);
    }
  }

  //Added by Giriprasath R
  //Function to validate phone number
  validatePhoneNumber(phoneNumber: string): boolean {
    const phoneNumberRegex = /^(|[0-9]{10})$/;
    return phoneNumberRegex.test(phoneNumber);
  }

  //Added by Giriprasath R
  //Get family tree value from the DB
  getFamilyTree() {
    const family_tree_id = this.familyId;
    this.apiServices.getFamilyTree(family_tree_id).subscribe((response) => {
      this.familyTreeResponse = response.data.familyTree;
      this.initFamilyTree();
    });
  }

  //Added by Giriprasath R
  //Post the family member(spouse) details value to the DB
  postFamilyDetails_spouse(_args, _sourceId, _divorcedId) {
    this.familyForm = this.formBuilder.group({
      relationship_type: [_args.relationType, Validators.required],
      source_id: [_sourceId, Validators.required],
      is_alive: [_args.active, Validators.required],
      is_divorced: [this.divorcedId, Validators.required],
      name: [_args.name, Validators.required],
      birth_date: [_args.born, Validators.required],
      family_tree_id: [this.familyId, Validators.required],
      phone_number: [_args.phone_number],
    });
    if (this.validatePhoneNumber(_args.phone_number)) {
      if (typeof _args.id === "string") {
        this.apiServices
          .addFamilyDetails(this.familyForm.value)
          .subscribe((response) => {
            if (response.error == false) {
              this.familyForm.reset();
              this.getFamilyTree();
            }
          });
      }
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire("Mobile number must be either 10 digits long or left empty.");
    }
  }

  //Added by Giriprasath R
  //Post the family member(Father, Mother, Son, Daughter, Brother, Sister) details value to the DB

  postFamilyDetails(_args, _sourceId, _divorcedId) {
    this.familyForm = this.formBuilder.group({
      relationship_type: [_args.relationType, Validators.required],
      source_id: [_sourceId, Validators.required],
      is_alive: [_args.active, Validators.required],
      name: [_args.name, Validators.required],
      birth_date: [_args.born, Validators.required],
      family_tree_id: [this.familyId, Validators.required],
      phone_number: [_args.phone_number],
    });
    if (this.validatePhoneNumber(_args.phone_number)) {
      if (typeof _args.id === "string") {
        this.apiServices
          .addFamilyDetails(this.familyForm.value)
          .subscribe((response) => {
            if (response.error == false) {
              this.familyForm.reset();
              this.getFamilyTree();
            }
          });
      } else {
        //Added by Giriprasath R
        //Update the all family member details value to the DB
        if (_args.pids.length > 0) {
          this.updatefamilyForm = this.formBuilder.group({
            id: [_sourceId],
            name: [_args.name],
            gender: [_args.gender],
            is_alive: [_args.active],
            birth_date: [_args.born],
            is_divorced: [_divorcedId],
            parent_id: [_args.pids],
            phone_number: [_args.phone_number],
          });
          this.apiServices
            .updateFamily(this.updatefamilyForm.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.updatefamilyForm.reset();
                this.getFamilyTree();
              }
            });
        } else if (_args.divorced.length > 0) {
          this.updatefamilyForm = this.formBuilder.group({
            id: [_sourceId],
            name: [_args.name],
            gender: [_args.gender],
            is_alive: [_args.active],
            birth_date: [_args.born],
            is_divorced: [_divorcedId],
            parent_id: [_args.divorced[0].id],
            phone_number: [_args.phone_number],
          });
          this.apiServices
            .updateFamily(this.updatefamilyForm.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.updatefamilyForm.reset();
                this.getFamilyTree();
              }
            });
        } else {
          this.updatefamilyForm = this.formBuilder.group({
            id: [_sourceId],
            name: [_args.name],
            gender: [_args.gender],
            is_alive: [_args.active],
            birth_date: [_args.born],
            phone_number: [_args.phone_number],
          });
          this.apiServices
            .updateFamily(this.updatefamilyForm.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.updatefamilyForm.reset();
                this.getFamilyTree();
              }
            });
        }
      }
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire("Mobile number must be either 10 digits long or left empty.");
    }
  }

  //Added by Giriprasath R
  //onSubmit Functionality
  onSubmit(_args) {
    if (_args.relationType == "spouse" && _args.gender == "female") {
      this.source_ID = _args.pids;
      this.divorced_ID = _args.relationship;
      this.sourceId = parseInt(this.source_ID);
      this.divorcedId = this.divorced_ID.toString();
      this.postFamilyDetails_spouse(_args, this.sourceId, this.divorcedId);
    } else if (_args.relationType == "spouse" && _args.gender == "male") {
      this.source_ID = _args.pids;
      this.divorced_ID = _args.relationship;
      this.sourceId = parseInt(this.source_ID);
      this.divorcedId = this.divorced_ID.toString();
      this.postFamilyDetails_spouse(_args, this.sourceId, this.divorcedId);
    } else if (_args.relationType == "son" && _args.gender == "male") {
      this.source_ID = _args.fid;
      const source_id_son = this.source_ID;
      this.divorcedId = "";
      this.postFamilyDetails(_args, source_id_son, this.divorcedId);
    } else if (_args.relationType == "daughter" && _args.gender == "female") {
      this.source_ID = _args.fid;
      const source_id_daughter = this.source_ID;
      this.divorcedId = "";
      this.postFamilyDetails(_args, source_id_daughter, this.divorcedId);
    } else if (_args.relationType == "father" && _args.gender == "male") {
      this.source_ID = _args.sourceId;
      this.sourceId = parseInt(this.source_ID);
      this.divorcedId = "";
      this.postFamilyDetails(_args, this.sourceId, this.divorcedId);
    } else if (_args.relationType == "mother" && _args.gender == "female") {
      this.source_ID = _args.sourceId;
      this.sourceId = parseInt(this.source_ID);
      this.divorced_ID = _args.relationship;
      this.divorcedId = this.divorced_ID.toString();
      this.postFamilyDetails(_args, this.sourceId, this.divorcedId);
    } else if (_args.relationType == "brother" && _args.gender == "male") {
      this.source_ID = _args.sourceId;
      const source_id_brother = this.source_ID;
      this.divorcedId = "";
      this.postFamilyDetails(_args, source_id_brother, this.divorcedId);
    } else if (_args.relationType == "sister" && _args.gender == "female") {
      this.source_ID = _args.sourceId;
      const source_id_sister = this.source_ID;
      this.divorcedId = "";
      this.postFamilyDetails(_args, source_id_sister, this.divorcedId);
    } else {
      if (_args.relationship) {
        this.source_ID = _args.id;
        this.divorced_ID = _args.relationship;
        this.divorcedId = this.divorced_ID.toString();
        this.postFamilyDetails(_args, this.source_ID, this.divorcedId);
      } else {
        this.source_ID = _args.id;
        this.divorced_ID = "";
        this.postFamilyDetails(_args, this.source_ID, this.divorcedId);
      }
    }
  }
}
function rgb(arg0: number, arg1: number, arg2: number) {
  throw new Error("Function not implemented.");
}
