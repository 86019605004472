<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-primary bg-soft">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Reset Password</h5>
                  <p>Reset Password with JanaVirucham</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="assets/images/profile-img.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="assets/images/logo.svg"
                      alt=""
                      class="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </a>
            </div>

            <div class="p-2">
              <div class="alert alert-success text-center mb-4" role="alert">
                Enter your Email and instructions will be sent to you!
              </div>

              <form
                class="needs-validation"
                name="passwordResetForm"
                [formGroup]="passwordResetForm"
                (ngSubmit)="onSubmit()"
                novalidate
              >
                <div class="mb-3">
                  <label for="email">Email</label>

                  <input
                    type="email"
                    formControlName="email"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    id="email"
                    placeholder="Enter email"
                  />

                  <div
                    *ngIf="submitted && f.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      type="password"
                      formControlName="password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.password.errors
                      }"
                      id="password"
                      placeholder="Enter password"
                    />

                    <button
                      class="btn btn-light ms-0"
                      type="button"
                      (click)="passwordVisibility()"
                    >
                      <i class="mdi mdi-eye-outline"></i>
                    </button>
                    <div
                      *ngIf="submitted && f.password.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.password.errors.required">
                        Password is required
                      </div>
                      <div *ngIf="f.password.errors.minlength">
                        Password must be at 8 characters
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="confirmPassword">Confirm Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      type="password"
                      formControlName="confirmPassword"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.confirmPassword.errors
                      }"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                    />

                    <button
                      class="btn btn-light ms-0"
                      type="button"
                      (click)="confirmPasswordVisibility()"
                    >
                      <i class="mdi mdi-eye-outline"></i>
                    </button>
                    <div
                      *ngIf="submitted && f.confirmPassword.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.confirmPassword.errors.required">
                        Confirm Password is required
                      </div>
                      <div *ngIf="f.confirmPassword.errors.minlength">
                        Confirm Password must be at 8 characters
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" type="submit">Reset</button>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card -->
        <div class="mt-5 text-center">
          <p>
            Remember It ?
            <a routerLink="/login" class="fw-medium text-primary">
              Sign In here</a
            >
          </p>
          <p>
            © {{ year }} JanaVirucham. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Ero Edge
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->
