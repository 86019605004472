<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>

<div id="tree"></div>
