import { Component } from "@angular/core";
import { DecimalPipe, DatePipe } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { SharedValueService } from "../services/share-values.service";
import _ from "lodash";

@Component({
  selector: "app-getfamilydetails",
  templateUrl: "./getfamilydetails.component.html",
  styleUrl: "./getfamilydetails.component.scss",
  providers: [DecimalPipe],
})
export class GetfamilydetailsComponent {
  //Below all functionality are added by Giriprasath R

  familyList: any[] = []; // storing the Familylist in the array
  templeList: any[] = []; // storing the templelist in the array
  statesList: any[] = []; // storing the stateslist in the array
  cityList: any[] = []; // storing the cityList in the array
  countryList: any[] = []; // storing the countrylist in the array
  filteredFamilyList: any[] = []; // Filtered Familylist to be displayed
  searchTerm: string = ""; // Search term entered by the user

  userTypeId: any; // stored the user session storage value
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService,
    private router: Router,
    private sharedValueService: SharedValueService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.initialize();
    this.getFamily();
    if (this.userTypeId == "3") {
      this.getCountry();
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.userTypeId = sessionStorage.getItem("userType");
  }

  //Converting the response status value into camelCase
  camelCase(_item) {
    if (_item == "approved") {
      _item = "Approved";
    } else if (_item == "rejected") {
      _item = "Rejected";
    } else {
      _item = "Pending";
    }
    return _item;
  }

  // Function to filter familyList based on search term
  filterFamilyList() {
    if (this.searchTerm.trim() === "") {
      this.filteredFamilyList = this.familyList;
    } else {
      this.filteredFamilyList = this.familyList.filter((item) =>
        item.created_by.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  //get the Familylist value for the field
  getFamily() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getFamilyList(`${NetworkService.getFamilyList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.familyList = response.data.familyList;
        this.filterFamilyList();
      });
  }

  //get the Familylist By temple ID value for the field

  getFamilyListByTempleId(event: any) {
    const temple_id = event.id;
    this.apiServices
      .getFamilyListByTempleId(temple_id)
      .subscribe((response) => {
        this.familyList = response.data.familyList;
        this.filterFamilyList();
      });
  }

  //get the Templelist value for the field
  getTemple(event: any) {
    const city_id = event.id;
    this.apiServices.getTempleById(city_id).subscribe((response) => {
      this.templeList = response.data.temples;
    });
  }

  //get the Citylist value for the field
  getCity(event: any) {
    const state_id = event.id;
    this.apiServices.getCityList(state_id).subscribe((response) => {
      this.cityList = response.data.cities;
    });
  }

  //get the Statelist value for the field
  getState(event: any) {
    const country_id = event.id;
    this.apiServices.getStateList(country_id).subscribe((response) => {
      this.statesList = response.data.states;
    });
  }

  //get the Countrylist value for the field
  getCountry() {
    this.apiServices
      .getCountryList(NetworkService.getCountry())
      .subscribe((response) => {
        this.countryList = response.data.countries;
      });
  }

  //Delete the family Data
  delete(_item: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          var deleteFamilyData: FormGroup;
          deleteFamilyData = this.formBuilder.group({
            family_tree_id: _item.id,
          });
          // delete the table data of family
          this.apiServices
            .deleteFamily(deleteFamilyData.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.getFamily();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your data has been deleted.",
                  "success"
                );
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your date is safe :)",
            "error"
          );
        }
      });
  }

  /**
   * This function navigate user form get family details to add family details component
   *
   */
  addFamilyDetailsPage() {
    this.router.navigate(["/addfamily"]);
  }

  /**
   * This function navigate user from get family details to view family details component for edit
   *
   */
  editFamilyDetailData() {
    this.router.navigate(["/viewfamilydetails"]);
  }

  /**
   * This function navigate user from get family details to view family details component for edit
   *
   */
  familyView(_item) {
    if (this.userTypeId === "2") {
      //send the id value to the SharedValueService component

      this.sharedValueService.familyTreeIdValue = _item.id;
      this.router.navigate(["/viewfamilydetails"]);
    } else {
      //send the id value to the SharedValueService component

      this.sharedValueService.familyTreeIdValue = _item.id;
      this.router.navigate(["/viewfamilydetails"]);
    }
  }

  /**
   *  Get the family data in csv format
   */
  exportFamilyData(_item) {
    const family_tree_id = _item.id;
    const family_created_user_name = _item.created_by;
    this.apiServices
      .getExportData(family_tree_id)
      .subscribe((response: any) => {
        console.log(response);
        const blob = new Blob([response.body], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${family_created_user_name}_family_data.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  //Date Formatter
  formatDate(inputDate: string): string {
    // Parse the input date string
    const date = new Date(inputDate);

    // Use DatePipe to format the date
    const formattedDate = this.datePipe.transform(date, "MM/dd/yyyy hh:mm a");

    return formattedDate || "-"; // Handle null or undefined values
  }

  //Reload the page
  reloadPage() {
    window.location.reload();
  }
}
