import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map, timeout } from "rxjs/operators";
import { AlertService } from "./alert.service";
import { NetworkService } from "./network.service";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class ApiCallService {
  constructor(
    private http: HttpClient,
    private alert: AlertService,
    private route: Router,
    private spinner: NgxSpinnerService
  ) {}

  postApiCallNoAuth(url: string, body: object) {
    this.spinner.show();
    return this.http
      .post(url, body, {
        headers: NetworkService.getHeader(),
      })
      .pipe(
        map((apiResponse: any) => {
          this.spinner.hide();
          if (apiResponse.error == false) {
            return apiResponse;
          } else {
            this.alert.fireToastF(apiResponse.message);
          }
        }),
        catchError((err) => {
          this.spinner.hide();
          if (err.status == 401) {
            let data: any = err.error;
            this.alert.fireToastF(data.data.error);
            this.route.navigate(["/login"]);
            localStorage.clear();
            sessionStorage.clear();
          } else if (err.status == 500) {
            this.alert.fireToastF("Server error, Try again later");
          }
          let data: any = err.error;
          this.alert.fireToastF(data.data.error);
          return "";
        })
      );
  }

  postApiCallAuth(url: string, body: object) {
    this.spinner.show();
    return this.http
      .post(url, body, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        timeout(50000), // 30 seconds timeout
        map((apiResponse: any) => {
          this.spinner.hide();
          if (apiResponse.error == false) {
            return apiResponse;
          } else {
            this.alert.fireToastF(apiResponse.message);
          }
        }),
        catchError((err) => {
          this.spinner.hide();
          if (err.status == 401) {
            let data: any = err.error;
            this.alert.fireToastF(data.data.error);
            this.route.navigate(["/login"]);
            localStorage.clear();
            sessionStorage.clear();
          } else if (err.status == 500) {
            this.alert.fireToastF("Server error, Try again later");
          }
          let data: any = err.error;
          this.alert.fireToastF(data.data.error);
          return "";
        })
      );
  }

  getApiCallNoAuth(url: string): any {
    this.spinner.show();
    return this.http
      .get(url, {
        headers: NetworkService.getHeader(),
      })
      .pipe(
        map((apiResponse: any) => {
          this.spinner.hide();
          if (apiResponse.error == false) {
            return apiResponse;
          } else {
            this.alert.fireToastF(apiResponse.message);
          }
        }),
        catchError((err) => {
          this.spinner.hide();
          if (err.status == 401) {
            let data: any = err.error;
            this.alert.fireToastF(data.data.error);
            this.route.navigate(["/login"]);
            localStorage.clear();
            sessionStorage.clear();
          } else if (err.status == 500) {
            this.alert.fireToastF("Server error, Try again later");
          }
          let data: any = err.error;
          this.alert.fireToastF(data.data.error);
          return "";
        })
      );
  }

  getApiCallAuth(url: string): any {
    this.spinner.show();
    return this.http
      .get(url, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((apiResponse: any) => {
          this.spinner.hide();
          if (apiResponse.error == false) {
            return apiResponse;
          } else {
            this.alert.fireToastF(apiResponse.message);
          }
        }),
        catchError((err: any) => {
          this.spinner.hide();
          return "";
        })
      );
  }

  //Added by Giriprasath
  downloadFile(url: string) {
    this.spinner.show();
    return this.http
      .get(url, {
        headers: NetworkService.getAuthHeader(),
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        timeout(50000), // 30 seconds timeout
        map((apiResponse: any) => {
          this.spinner.hide();
          if (apiResponse.status === 200) {
            return apiResponse;
          } else {
            this.alert.fireToastF(apiResponse.message);
          }
        }),
        catchError((err) => {
          this.spinner.hide();
          return "";
        })
      );
  }
}
