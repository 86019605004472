import { Component } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { SharedValueService } from "../services/share-values.service";

@Component({
  selector: "app-approve-pending-list",
  templateUrl: "./approve-pending-list.component.html",
  styleUrl: "./approve-pending-list.component.scss",
  providers: [DecimalPipe],
})
export class ApprovePendingListComponent {
  //Below all functionality are added by Giriprasath R

  approvePendingList: any[] = []; // storing the approvePendingList in the array
  approveForm: FormGroup;
  rejectForm: FormGroup;
  submitted = false;
  userTypeId: any; // stored the user session storage value

  filteredApprovePendingList: any[] = []; // Filtered ApprovePendingList to be displayed
  searchTerm: string = ""; // Search term entered by the user

  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService,
    private router: Router,
    private sharedValueService: SharedValueService
  ) {}

  ngOnInit() {
    this.initialize();
    this.getPendingFamilyApprovals();
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.userTypeId = sessionStorage.getItem("userType");
  }

  // Function to filter Approve/Pending List based on search term
  filterApprovePendingList() {
    if (this.searchTerm.trim() === "") {
      this.filteredApprovePendingList = this.approvePendingList;
    } else {
      this.filteredApprovePendingList = this.approvePendingList.filter((item) =>
        item.family_name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  // Get the Approve/Pending List in the DB
  getPendingFamilyApprovals() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getPendingFamilyApprovals(
        `${NetworkService.getPendingFamilyApprovals()}?_=${timestamp}`
      )
      .subscribe((response) => {
        this.approvePendingList = response.data.pendingApprovals;
        this.filterApprovePendingList();
      });
  }

  // Approve the family
  approve(_item) {
    this.approveForm = this.formBuilder.group({
      family_tree_id: [_item.id],
      status: ["approved"],
    });

    //Post the approve data to the DB
    this.apiServices
      .approveRejectFamily(this.approveForm.value)
      .subscribe((response) => {
        if (response.error == false) {
          this.getPendingFamilyApprovals();
        }
      });
  }

  //Reject the family
  reject(_item) {
    this.rejectForm = this.formBuilder.group({
      family_tree_id: [_item.id],
      status: ["rejected"],
    });

    //Post the reject data to the DB
    this.apiServices
      .approveRejectFamily(this.rejectForm.value)
      .subscribe((response) => {
        if (response.error == false) {
          this.getPendingFamilyApprovals();
        }
      });
  }

  /**
   * This function navigate user from get family details to view family details component for edit
   *
   */
  familyView(_item) {
    //send the id value to the SharedValueService component

    this.sharedValueService.familyTreeIdValue = _item.id;
    this.router.navigate(["/viewfamilydetails"]);
  }
}
