<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<!-- Kulam List Table -->
<div class="container-fluid" *ngIf="kulamList.length >= 1">
  <h5 class="kulamheading">kulam List</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterKulamList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button
                  type="button"
                  class="btn btn-success btn-rounded mb-2 me-2"
                  (click)="openModal(content)"
                >
                  <i class="mdi mdi-plus mr-1"></i> Add
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of filteredKulamList" id="{{ item.kula }}">
                  <td>
                    {{ item.kula }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="editDataGet(item, content)"
                        ><i class="mdi mdi-pencil font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-danger"
                        (click)="delete(item)"
                        ><i class="mdi mdi-delete font-size-18"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Kulam List Form Create Model -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add Kulam</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="[modal.dismiss('Cross click'), closeForm()]"
    ></button>
  </div>
  <form
    (ngSubmit)="[onSubmit(), modal.close('Close click')]"
    [formGroup]="kulamForm"
  >
    <div class="modal-body">
      <div class="row">
        <div class="mb-3">
          <label for="validationCustom03">Kulam</label>
          <input
            type="text"
            class="form-control"
            id="kula"
            formControlName="kula"
            placeholder="kulam"
            [ngClass]="{ 'is-invalid': submitted && f.kula.errors }"
          />
          <div *ngIf="submitted && f.kula.errors" class="invalid-feedback">
            <span *ngIf="f.kula.errors.required">Please provide a Kulam.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="[modal.close('Close click'), closeForm()]"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn">Add</button>
      </div>
    </div>
  </form>
</ng-template>
<!--End Modal -->

<!-- Approval Kulam Table -->
<div
  class="container-fluid"
  *ngIf="userTypeId === '2' && unApprovedKulamList.length >= 1"
>
  <h5 class="kulamheading">Approval kulam</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTermUnApproved"
                    (input)="filterUnApprovedKulamList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredUnApprovedKulamList"
                  id="{{ item.kula }}"
                >
                  <td>
                    {{ item.kula }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="editApprovalKulam(item, contentapproval)"
                        ><i class="mdi mdi-pencil font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="approve(item)"
                        ><i class="mdi mdi-check font-size-18"></i> Approve</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Approval Kulam Form Create Model -->
<ng-template #contentapproval let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"></h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="[modal.dismiss('Cross click'), closeForm()]"
    ></button>
  </div>
  <form
    (ngSubmit)="[onSubmitApprovalKulam(), modal.close('Close click')]"
    [formGroup]="kulamForm"
  >
    <div class="modal-body">
      <div class="row">
        <div class="mb-3">
          <label for="validationCustom03">Kulam</label>
          <input
            type="text"
            class="form-control"
            id="kula"
            formControlName="kula"
            placeholder="kulam"
            [ngClass]="{ 'is-invalid': submitted && f.kula.errors }"
          />
          <div *ngIf="submitted && f.kula.errors" class="invalid-feedback">
            <span *ngIf="f.kula.errors.required">Please provide a Kulam.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="[modal.close('Close click'), closeForm()]"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn"></button>
      </div>
    </div>
  </form>
</ng-template>

<!-- No data has been found the below div will shown on the component -->
<div
  class="account-pages my-5 pt-5"
  *ngIf="kulamList.length < 1 && unApprovedKulamList.length < 1"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <div class="mt-5 text-center">
            <h4 class="text-primary">
              Sorry, No kulam details has been assigned.
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
