import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Consts } from "../utils/consts";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  constructor() {}

  static server_url(): string {
    return Consts.URL();
  }

  static server_v(): string {
    return "/api/";
  }

  static getAuthHeader(): HttpHeaders {
    let header = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + this.authToken(),
    });
    return header;
  }

  static getHeader(): HttpHeaders {
    let header = new HttpHeaders()
      .set("Cache-Control", "no-cache")
      .set("Access-Control-Allow-Origin", "*");
    return header;
  }

  static authToken(): any {
    return sessionStorage.getItem("accessToken") || undefined;
  }

  // Authentication
  static login(): string {
    return this.server_url() + this.server_v() + "login";
  }

  //added by Giriprasath

  static register(): string {
    return this.server_url() + this.server_v() + "register";
  }

  static addAdmin(): string {
    return this.server_url() + this.server_v() + "addAdmin";
  }

  static addKulam(): string {
    return this.server_url() + this.server_v() + "addKula";
  }

  static addGotram(): string {
    return this.server_url() + this.server_v() + "addGotra";
  }

  static addTemple(): string {
    return this.server_url() + this.server_v() + "addTemple";
  }

  static addFatherMother(): string {
    return this.server_url() + this.server_v() + "addFatherMother";
  }

  static addMomFatherMother(): string {
    return this.server_url() + this.server_v() + "addMomFatherMother";
  }

  static addDadFatherMother(): string {
    return this.server_url() + this.server_v() + "addDadFatherMother";
  }

  static addFamilyDetails(): string {
    return this.server_url() + this.server_v() + "addFamilyDetails";
  }

  static addMergeFamily(): string {
    return this.server_url() + this.server_v() + "mergeFamily";
  }

  static updateAdmin(): string {
    return this.server_url() + this.server_v() + "updateAdmin";
  }

  static updateKulam(): string {
    return this.server_url() + this.server_v() + "updateKula";
  }

  static updateKothram(): string {
    return this.server_url() + this.server_v() + "updateGotra";
  }

  static updateTemple(): string {
    return this.server_url() + this.server_v() + "updateTemple";
  }

  static updateFamily(): string {
    return this.server_url() + this.server_v() + "updateFamily";
  }

  static getAdminList(): string {
    return this.server_url() + this.server_v() + "getAdminList";
  }

  static getGotram(): string {
    return this.server_url() + this.server_v() + "getGotras";
  }

  static getKulam(): string {
    return this.server_url() + this.server_v() + "getKulas";
  }

  static getGotramById(): string {
    return this.server_url() + this.server_v() + "getGotras?kula_id=";
  }

  static getTempleById(): string {
    return this.server_url() + this.server_v() + "getTemples?city_id=";
  }

  static getTempleList(): string {
    return this.server_url() + this.server_v() + "getTemples";
  }

  static getCountry(): string {
    return this.server_url() + this.server_v() + "getCountryList";
  }

  static getState(): string {
    return this.server_url() + this.server_v() + "getStateList?country_id=";
  }

  static getCity(): string {
    return this.server_url() + this.server_v() + "getCitiesByStateId?state_id=";
  }

  static getFamilyList(): string {
    return this.server_url() + this.server_v() + "getFamilyList";
  }

  static getFamilyListByTempleId(): string {
    return this.server_url() + this.server_v() + "getFamilyList?temple_id=";
  }

  static getFamilyTree(): string {
    return (
      this.server_url() + this.server_v() + "getFamilyTree?family_tree_id="
    );
  }

  static getPendingFamilyApprovals(): string {
    return this.server_url() + this.server_v() + "getPendingFamilyApprovals";
  }

  static getFamiliesByTemple(): string {
    return this.server_url() + this.server_v() + "getFamiliesByTemple";
  }

  static getExportData(family_tree_id: any): string {
    return (
      this.server_url() +
      this.server_v() +
      `exportFamilyData?family_tree_id=${family_tree_id} &temple_id=null`
    );
  }

  static getExportTempleData(temple_id: any): string {
    return (
      this.server_url() +
      this.server_v() +
      `exportFamilyData?family_tree_id=null&temple_id=${temple_id} `
    );
  }

  static approveRejectFamily(): string {
    return this.server_url() + this.server_v() + "approveRejectFamily";
  }

  static sendOtp(): string {
    return this.server_url() + this.server_v() + "sendOTP";
  }

  static verifyOtp(): string {
    return this.server_url() + this.server_v() + "verifyOtp";
  }

  static deleteMasterData(): string {
    return this.server_url() + this.server_v() + "deleteMasterData";
  }

  static deleteAdmin(): string {
    return this.server_url() + this.server_v() + "deleteAdmin";
  }

  static deleteFamily(): string {
    return this.server_url() + this.server_v() + "deleteFamily";
  }
}
