<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>

<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-10">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-12">
                <div class="horizontal-steps">
                  <div
                    class="step"
                    [class.active]="currentStep === 1"
                    [class.completed]="currentStep > 1"
                  >
                    <div class="step-content">
                      <div class="image-container maleImage">
                        <img src="/assets/images/male.svg" alt="Step 1" />
                        <p>Name</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="step"
                    [class.active]="currentStep === 2"
                    [class.completed]="currentStep > 2"
                  >
                    <div class="step-content">
                      <div class="image-container fatherImage">
                        <img src="/assets/images/father.svg" alt="Father" />
                        <p>Father</p>
                      </div>
                      <div class="image-container motherImage">
                        <img src="/assets/images/mother.svg" alt="Mother" />
                        <p>Mother</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="step"
                    [class.active]="currentStep === 3"
                    [class.completed]="currentStep > 3"
                  >
                    <div class="step-content">
                      <div class="image-container grandpa1Image">
                        <img src="/assets/images/grandpa1.svg" alt="Father" />
                        <p>Grandpa</p>
                      </div>
                      <div class="image-container grandma1Image">
                        <img src="/assets/images/grandma1.svg" alt="Mother" />
                        <p>Grandma</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="step"
                    [class.active]="currentStep === 4"
                    [class.completed]="currentStep > 4"
                  >
                    <div class="step-content">
                      <div class="image-container grandpa2Image">
                        <img src="/assets/images/grandpa2.svg" alt="Father" />
                        <p>Grandpa</p>
                      </div>
                      <div class="image-container grandma2Image">
                        <img src="/assets/images/grandma2.svg" alt="Mother" />
                        <p>Grandma</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0" *ngIf="currentStep === 1">
            <div class="p-2">
              <form
                class="needs-validation"
                (ngSubmit)="nextStep()"
                [formGroup]="validationform"
              >
                <div class="row">
                  <label>Me</label>
                  <div class="mb-3">
                    <div class="gender-item">
                      <input
                        type="radio"
                        formControlName="user_gender"
                        [value]="'male'"
                        id="male"
                        (change)="genderMaleChange()"
                      />
                      <label for="male">Male</label>
                      <input
                        type="radio"
                        formControlName="user_gender"
                        [value]="'female'"
                        id="female"
                        class="female-gender-align"
                        (change)="genderFemaleChange()"
                      />
                      <label for="female">Female</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3 fieldAlign_1">
                      <label class="form-label">Name </label>
                      <input
                        type="text"
                        class="form-control"
                        id="user_name"
                        formControlName="user_name"
                        placeholder="username"
                        (change)="userNameChange()"
                        [ngClass]="{
                          'is-invalid': submit && form_1.user_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_1.user_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_1.user_name.errors.required"
                          >Please provide a valid User name</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 fieldAlign_1">
                    <div class="mb-3">
                      <mat-form-field
                        class="w-100 date-picker-field"
                        appearance="outline"
                      >
                        <input
                          matInput
                          [matDatepicker]="userpicker"
                          id="user_dob"
                          formControlName="user_dob"
                          placeholder="Date Of Birth"
                          (click)="userpicker.open()"
                          (dateInput)="userDOBChange($event)"
                          [ngClass]="{
                            'is-invalid': submit && form_1.user_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_1.user_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_1.user_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="userpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #userpicker
                          touchUi="true"
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-md-6 phoneNumberFieldAlign">
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="user_phone_number"
                        formControlName="user_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_1.user_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_1.user_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_1.user_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row marginAlignForMomField">
                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label> Your Mother </label>
                      <input
                        type="text"
                        formControlName="mother_name"
                        class="form-control"
                        id="mother_name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submit && form_1.mother_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_1.mother_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_1.mother_name.errors.required"
                          >Please provide a valid Name</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="mother_phone_number"
                        formControlName="mother_phone_number"
                        placeholder="Mobile number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_1.mother_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_1.mother_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_1.mother_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="motherpicker"
                          id="mother_dob"
                          formControlName="mother_dob"
                          placeholder="Date Of Birth"
                          (click)="motherpicker.open()"
                          (ngModelChange)="momDobChange()"
                          [ngClass]="{
                            'is-invalid': submit && form_1.mother_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_1.mother_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_1.mother_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="motherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #motherpicker
                          touchUi="true"
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label> Your Father </label>
                      <input
                        type="text"
                        class="form-control"
                        id="father_name"
                        formControlName="father_name"
                        placeholder="Name"
                        (change)="fatheNameChange()"
                        [ngClass]="{
                          'is-invalid': submit && form_1.father_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_1.father_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_1.father_name.errors.required"
                          >Please provide a valid Name</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="father_phone_number"
                        formControlName="father_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_1.father_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_1.father_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_1.father_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="fatherpicker"
                          id="father_dob"
                          formControlName="father_dob"
                          placeholder="Date Of Birth"
                          (click)="fatherpicker.open()"
                          (ngModelChange)="dadDobChange()"
                          [ngClass]="{
                            'is-invalid': submit && form_1.father_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_1.father_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_1.father_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="fatherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #fatherpicker
                          touchUi="true"
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label>Choose Family Captain</label>
                  <div class="mb-3">
                    <div class="captain-item">
                      <input
                        type="checkbox"
                        id="userCaptain"
                        formControlName="is_user_family_captain"
                        (change)="userFamilyCaptain()"
                      />
                      <label>{{ customerDetailsValue[0] }}</label>
                      <input
                        type="checkbox"
                        id="userDadCaptain"
                        formControlName="is_dad_family_captain"
                        class="captain-checkbox-align"
                        (change)="DadFamilyCaptain()"
                      />
                      <label>{{ customerDetailsValue[1] }}</label>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    class="btn-design btn btn-success"
                    type="submit"
                    id="add-btn"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="card-body pt-0" *ngIf="currentStep === 2">
            <div class="p-2">
              <form
                class="needs-validation"
                (ngSubmit)="nextStep_2()"
                [formGroup]="validationform_1"
              >
                <div class="row">
                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label> Your Mother </label>
                      <input
                        type="text"
                        formControlName="mother_name"
                        class="form-control"
                        id="mother_name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submit && form_2.mother_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_2.mother_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_2.mother_name.errors.required"
                          >Please provide a valid Name</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="mother_phone_number"
                        formControlName="mother_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_2.mother_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_2.mother_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_2.mother_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="motherpicker"
                          id="mother_dob"
                          formControlName="mother_dob"
                          placeholder="Date Of Birth"
                          (click)="motherpicker.open()"
                          (ngModelChange)="motherDobChange()"
                          [ngClass]="{
                            'is-invalid': submit && form_2.mother_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_2.mother_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_2.mother_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="motherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #motherpicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="mb-3">
                      <div>
                        <input
                          type="checkbox"
                          id="checkbox1"
                          formControlName="mother_alive"
                          (change)="getCheckBoxValue_1()"
                        />
                        <label class="form-check-label"> Is Alive </label>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        (click)="previousStep_1()"
                        class="btn-design btn-align-previous btn btn-success"
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label> Your Father </label>
                      <input
                        type="text"
                        class="form-control"
                        id="father_name"
                        formControlName="father_name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submit && form_2.father_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_2.father_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_2.father_name.errors.required"
                          >Please provide a valid FirstName</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="father_phone_number"
                        formControlName="father_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_2.father_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_2.father_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_2.father_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="fatherpicker"
                          id="father_dob"
                          formControlName="father_dob"
                          placeholder="Date Of Birth"
                          (click)="fatherpicker.open()"
                          (ngModelChange)="fatherDobChange()"
                          [ngClass]="{
                            'is-invalid': submit && form_2.father_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_2.father_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_2.father_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="fatherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #fatherpicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="mb-3">
                      <div>
                        <input
                          type="checkbox"
                          id="checkbox2"
                          formControlName="father_alive"
                          (change)="getCheckBoxValue_1()"
                        />
                        <label> Is Alive </label>
                      </div>
                      <div>
                        <button
                          type="submit"
                          class="btn-align-next btn-design btn btn-success"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-body pt-0" *ngIf="currentStep === 3">
            <div class="p-2">
              <form
                class="needs-validation"
                (ngSubmit)="nextStep_3()"
                [formGroup]="validationform_2"
              >
                <div class="row">
                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label style="text-wrap: nowrap">
                        Your Mother's Mother
                      </label>
                      <input
                        type="text"
                        formControlName="mom_mother_name"
                        class="form-control"
                        id="mom_mother_name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submit && form_3.mom_mother_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_3.mom_mother_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_3.mom_mother_name.errors.required"
                          >Please provide a valid FirstName</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="mom_mother_phone_number"
                        formControlName="mom_mother_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_3.mom_mother_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_3.mom_mother_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span
                          *ngIf="form_3.mom_mother_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="mommotherpicker"
                          id="mom_mother_dob"
                          formControlName="mom_mother_dob"
                          placeholder="Date Of Birth"
                          (click)="mommotherpicker.open()"
                          [ngClass]="{
                            'is-invalid': submit && form_3.mom_mother_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_3.mom_mother_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_3.mom_mother_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="mommotherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #mommotherpicker
                          touchUi="true"
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3">
                      <div>
                        <input
                          type="checkbox"
                          id="checkbox3"
                          formControlName="mom_mother_alive"
                          (change)="getCheckBoxValue_2()"
                        />
                        <label> Is Alive </label>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        (click)="previousStep_2()"
                        class="btn-align-previous btn-design btn btn-success"
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label style="text-wrap: nowrap">
                        Your Mother's Father
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="mom_father_name"
                        formControlName="mom_father_name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submit && form_3.mom_father_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_3.mom_father_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_3.mom_father_name.errors.required"
                          >Please provide a valid FirstName</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="mom_father_phone_number"
                        formControlName="mom_father_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_3.mom_father_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_3.mom_father_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span
                          *ngIf="form_3.mom_father_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="momfatherpicker"
                          id="mom_father_dob"
                          formControlName="mom_father_dob"
                          placeholder="Date Of Birth"
                          (click)="momfatherpicker.open()"
                          [ngClass]="{
                            'is-invalid': submit && form_3.mom_father_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_3.mom_father_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_3.mom_father_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="momfatherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #momfatherpicker
                          touchUi="true"
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3">
                      <div>
                        <input
                          type="checkbox"
                          id="checkbox4"
                          formControlName="mom_father_alive"
                          (change)="getCheckBoxValue_2()"
                        />
                        <label> Is Alive </label>
                      </div>
                      <div>
                        <button
                          type="submit"
                          class="btn-align-next btn-design btn btn-success"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-body pt-0" *ngIf="currentStep === 4">
            <div class="p-2">
              <form
                class="needs-validation"
                (ngSubmit)="validSubmit()"
                [formGroup]="validationform_3"
              >
                <div class="row">
                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label style="text-wrap: nowrap">
                        Your Father's Mother
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="dad_mother_name"
                        formControlName="dad_mother_name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submit && form_4.dad_mother_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_4.dad_mother_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_4.dad_mother_name.errors.required"
                          >Please provide a valid Name</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="dad_mother_phone_number"
                        formControlName="dad_mother_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_4.dad_mother_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_4.dad_mother_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span
                          *ngIf="form_4.dad_mother_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="dadmotherpicker"
                          id="dad_mother_dob"
                          formControlName="dad_mother_dob"
                          placeholder="Date Of Birth"
                          (click)="dadmotherpicker.open()"
                          [ngClass]="{
                            'is-invalid': submit && form_4.dad_mother_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_4.dad_mother_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_4.dad_mother_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="dadmotherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dadmotherpicker
                          touchUi="true"
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3">
                      <div>
                        <input
                          type="checkbox"
                          id="checkbox5"
                          formControlName="dad_mother_alive"
                          (change)="getCheckBoxValue_3()"
                        />
                        <label> Is Alive </label>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        (click)="previousStep_3()"
                        class="btn-align-previous btn-design btn btn-success"
                      >
                        Previous
                      </button>
                    </div>
                  </div>

                  <div class="col-xl-6 col-sm-6">
                    <div class="mb-3">
                      <label style="text-wrap: nowrap">
                        Your Father's Father
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="dad_father_name"
                        formControlName="dad_father_name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submit && form_4.dad_father_name.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_4.dad_father_name.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="form_4.dad_father_name.errors.required"
                          >Please provide a valid FirstName</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="dad_father_phone_number"
                        formControlName="dad_father_phone_number"
                        placeholder="Mobile Number"
                        [ngClass]="{
                          'is-invalid':
                            submit && form_4.dad_father_phone_number.errors
                        }"
                      />
                      <div
                        *ngIf="submit && form_4.dad_father_phone_number.errors"
                        class="invalid-feedback"
                      >
                        <span
                          *ngIf="form_4.dad_father_phone_number.errors.pattern"
                          >Mobile number must be either 10 digits long or left
                          empty.</span
                        >
                      </div>
                    </div>

                    <div class="mb-3">
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="dadfatherpicker"
                          id="dad_father_dob"
                          formControlName="dad_father_dob"
                          placeholder="Date Of Birth"
                          (click)="dadfatherpicker.open()"
                          [ngClass]="{
                            'is-invalid': submit && form_4.dad_father_dob.errors
                          }"
                        />
                        <div
                          *ngIf="submit && form_4.dad_father_dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="form_4.dad_father_dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="dadfatherpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dadfatherpicker
                          touchUi="true"
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3">
                      <div>
                        <input
                          type="checkbox"
                          id="checkbox6"
                          formControlName="dad_father_alive"
                          (change)="getCheckBoxValue_3()"
                        />
                        <label> Is Alive </label>
                      </div>
                      <div>
                        <button
                          type="submit"
                          class="btn-align-next btn-design btn btn-success"
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
