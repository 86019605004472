<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<!-- Temple List Table-->
<div class="container-fluid" *ngIf="templeList.length >= 1">
  <h5 class="templeheading">Temple List</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterTempleList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button
                  type="button"
                  class="btn btn-success btn-rounded mb-2 me-2"
                  (click)="openModal(content)"
                >
                  <i class="mdi mdi-plus mr-1"></i> Add
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredTempleList"
                  id="{{ item.temple }}"
                >
                  <td>
                    {{ item.temple }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        (click)="editDataGet(item, content)"
                        ><i class="mdi mdi-pencil font-size-18"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        (click)="delete(item)"
                        ><i class="mdi mdi-delete font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        (click)="exportFamilyData(item)"
                        ><i
                          class="mdi mdi-application-export font-size-18 export_btn_align"
                        ></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Temple List Form Create Model-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add Temple</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="[modal.dismiss('Cross click'), closeForm()]"
    ></button>
  </div>
  <form
    (ngSubmit)="[onSubmit(), modal.close('Close click')]"
    [formGroup]="templeForm"
  >
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label">Country</label>

            <ng-select
              [items]="countryList"
              bindLabel="country"
              bindValue="id"
              formControlName="country_id"
              [multiple]="false"
              [clearable]="!isCountryDropdownClearable()"
              (change)="getState($event)"
              [ngClass]="{ 'is-invalid': submitted && f.country_id.errors }"
            ></ng-select>

            <div
              *ngIf="submitted && f.country_id.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.country_id.errors.required"
                >Please select a country.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label">State</label>

            <ng-select
              [items]="statesList"
              bindLabel="state_name"
              bindValue="id"
              formControlName="state_id"
              [multiple]="false"
              [clearable]="!isStateDropdownClearable()"
              (change)="getCity($event)"
              [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
            ></ng-select>

            <div
              *ngIf="submitted && f.state_id.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.state_id.errors.required"
                >Please select a state.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label">City</label>

            <ng-select
              [items]="cityList"
              bindLabel="city_name"
              bindValue="id"
              formControlName="city_id"
              [multiple]="false"
              [clearable]="!isCityDropdownClearable()"
              (change)="getTemple($event)"
              [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
            ></ng-select>

            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
              <span *ngIf="f.city_id.errors.required"
                >Please select a city.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="mb-3">
            <label class="form-label">Temple</label>

            <input
              type="text"
              formControlName="temple"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.temple.errors }"
              id="temple"
              placeholder="Temple"
            />

            <div *ngIf="submitted && f.temple.errors" class="invalid-feedback">
              <div *ngIf="f.temple.errors.required">Temple is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Address</label>

        <textarea
          rows="4"
          cols="50"
          formControlName="address"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
          id="address"
          placeholder="Address"
        ></textarea>

        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
          <div *ngIf="f.address.errors.required">Address is required</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="[modal.close('Close click'), closeForm()]"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn">Add</button>
      </div>
    </div>
  </form>
</ng-template>
<!--End Modal -->

<!-- Approval Temple Table-->
<div
  class="container-fluid"
  *ngIf="userTypeId === '3' && unApprovedTempleList.length >= 1"
>
  <h5 class="templeheading">Approval Temple</h5>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterUnApprovedTempleList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredUnApprovedTempleList"
                  id="{{ item.temple }}"
                >
                  <td>
                    {{ item.temple }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        (click)="editApprovalTemple(item, contentapproval)"
                        ><i class="mdi mdi-pencil font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="approve(item)"
                        ><i class="mdi mdi-check font-size-18"></i> Approve</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Approval Temple Form Create Model-->
<ng-template #contentapproval let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"></h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="[modal.dismiss('Cross click'), closeForm()]"
    ></button>
  </div>
  <form
    (ngSubmit)="[onSubmitApprovalTemple(), modal.close('Close click')]"
    [formGroup]="templeForm"
  >
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label">Country</label>

            <ng-select
              [items]="countryList"
              bindLabel="country"
              bindValue="id"
              formControlName="country_id"
              [multiple]="false"
              [clearable]="!isCountryDropdownClearable()"
              (change)="getState($event)"
              [ngClass]="{ 'is-invalid': submitted && f.country_id.errors }"
            ></ng-select>

            <div
              *ngIf="submitted && f.country_id.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.country_id.errors.required"
                >Please select a country.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label">State</label>

            <ng-select
              [items]="statesList"
              bindLabel="state_name"
              bindValue="id"
              formControlName="state_id"
              [multiple]="false"
              [clearable]="!isStateDropdownClearable()"
              (change)="getCity($event)"
              [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
            ></ng-select>

            <div
              *ngIf="submitted && f.state_id.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.state_id.errors.required"
                >Please select a state.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label">City</label>

            <ng-select
              [items]="cityList"
              bindLabel="city_name"
              bindValue="id"
              formControlName="city_id"
              [multiple]="false"
              [clearable]="!isCityDropdownClearable()"
              (change)="getTemple($event)"
              [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
            ></ng-select>

            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
              <span *ngIf="f.city_id.errors.required"
                >Please select a city.</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="mb-3">
            <label class="form-label">Temple</label>

            <input
              type="text"
              formControlName="temple"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.temple.errors }"
              id="temple"
              placeholder="Temple"
            />

            <div *ngIf="submitted && f.temple.errors" class="invalid-feedback">
              <div *ngIf="f.temple.errors.required">Temple is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Address</label>

        <textarea
          rows="4"
          cols="50"
          formControlName="address"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
          id="address"
          placeholder="Address"
        ></textarea>

        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
          <div *ngIf="f.address.errors.required">Address is required</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="[modal.close('Close click'), closeForm()]"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn">Add</button>
      </div>
    </div>
  </form>
</ng-template>
<!--End Modal -->

<!-- No data has been found the below div will shown on the component -->
<div
  class="account-pages my-5 pt-5"
  *ngIf="templeList.length < 1 && unApprovedTempleList.length < 1"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <div class="mt-5 text-center">
            <h4 class="text-primary">
              Sorry, No Temple details has been assigned.
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
