<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4">
      <div class="card overflow-hidden">
        <div class="bg-soft bg-primary">
          <div class="row">
            <div class="col-7">
              <div class="text-primary p-3">
                <h5 class="text-primary">Welcome Back !</h5>
                <p>{{ userName }}</p>
              </div>
            </div>
            <div class="col-5 align-self-end">
              <img
                src="assets/images/profile-img.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-sm-8">
              <div class="avatar-md profile-user-wid mb-4">
                <img
                  src="assets/images/users/profile_img_1.jpg"
                  alt=""
                  class="img-thumbnail rounded-circle"
                />
              </div>
              <p class="text-muted mb-0 text-truncate">{{ userEmail }}</p>
              <p class="text-muted mb-0 text-truncate">{{ userRole }}</p>
            </div>

            <div class="col-sm-4">
              <div class="pt-4">
                <div class="row">
                  <div class="mt-4">
                    <a routerLink="/getfamily" class="btn btn-primary btn-sm"
                      >View Family Details
                      <i class="mdi mdi-arrow-right ms-1"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </div>
</div>
