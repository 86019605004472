import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrl: "./profile.component.scss",
})
export class ProfileComponent implements OnInit {
  //Below all functionality are added by Giriprasath R

  userName: string; //store the username value from the session storage
  userEmail: string; //store the userEmail value from the session storage
  userID: string; //store the userID value from the session storage
  userRole: string; // store the userRole based on the condition
  constructor() {}

  ngOnInit() {
    this.profileCard();
  }
  // The below function give the user details to the profile card
  profileCard() {
    this.userName = sessionStorage.getItem("userName");
    this.userEmail = sessionStorage.getItem("userEmail");
    this.userID = sessionStorage.getItem("userType");

    if (this.userID == "1") {
      this.userRole = "Customer";
    } else if (this.userID == "2") {
      this.userRole = "Admin";
    } else if (this.userID == "3") {
      this.userRole = "Super Admin";
    } else {
      this.userRole = "";
    }
  }
}
