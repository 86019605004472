<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-5">
            <div class="p-2">
              <form [formGroup]="bulkMessageForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6" *ngIf="userID == 2">
                      <div class="mb-4">
                        <label class="form-label">Family List</label>

                        <ng-select
                          [items]="familyList"
                          bindLabel="created_by"
                          bindValue="id"
                          formControlName="source_family_id"
                          [multiple]="false"
                          [clearable]="!sourceFamilyDropdownClearable()"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.source_family_id.errors,
                            'custom-ng-select': true
                          }"
                        ></ng-select>

                        <div
                          *ngIf="submitted && f.source_family_id.errors"
                          class="invalid-feedback"
                        >
                          <span *ngIf="f.source_family_id.errors.required"
                            >Please select a family details.</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6" *ngIf="userID == 3">
                      <div class="mb-4">
                        <label class="form-label">Temple List</label>

                        <ng-select
                          [items]="templeList"
                          bindLabel="temple"
                          bindValue="id"
                          formControlName="target_family_id"
                          [multiple]="false"
                          [clearable]="!destinationFamilyDropdownClearable()"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.target_family_id.errors,
                            'custom-ng-select': true
                          }"
                        ></ng-select>

                        <div
                          *ngIf="submitted && f.target_family_id.errors"
                          class="invalid-feedback"
                        >
                          <span *ngIf="f.target_family_id.errors.required"
                            >Please select a temple list details
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Message</label>

                      <textarea
                        rows="4"
                        cols="50"
                        formControlName="message"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': submitted && f.message.errors
                        }"
                        id="address"
                        placeholder="Message"
                      ></textarea>

                      <div
                        *ngIf="submitted && f.message.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.message.errors.required">
                          Message is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 d-grid">
                  <button class="btn btn-success btn-rounded" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
