import { Component } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";

import Swal from "sweetalert2";
//import { DataService } from "./data.service";

@Component({
  selector: "app-temple",
  templateUrl: "./temple.component.html",
  styleUrl: "./temple.component.scss",
})
export class TempleComponent {
  //Below all functionality are added by Giriprasath R

  templeList: any[] = []; // storing the templelist in the array
  statesList: any[] = []; // storing the stateslist in the array
  cityList: any[] = []; // storing the cityList in the array
  countryList: any[] = []; // storing the countryList in the array
  templeForm: FormGroup;
  submitted = false;

  filteredTempleList: any[] = []; // Filtered templeList to be displayed
  searchTerm: string = ""; // Search term entered by the user

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.getTempleList();
    this.getCountry();

    //Form Validations
    this.templeForm = this.formBuilder.group({
      id: [""],
      state_id: ["", Validators.required],
      city_id: ["", Validators.required],
      country_id: ["", Validators.required],
      temple: ["", Validators.required],
      address: ["", Validators.required],
      master_type: ["temple"], //this value is used in delete api for deleting tthe data in table
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.templeForm.controls;
  }

  // Function to filter templeList based on search term
  filterTempleList() {
    if (this.searchTerm.trim() === "") {
      this.filteredTempleList = this.templeList;
    } else {
      this.filteredTempleList = this.templeList.filter((item) =>
        item.temple.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  //get the Temple list value for the table
  getTempleList() {
    const timestamp = new Date().getTime();
    this.apiService
      .getTempleList(`${NetworkService.getTempleList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.templeList = response.data.temples;
        this.filterTempleList();
      });
  }

  //get the Temple value for the field
  getTemple(event: any) {
    this.apiService
      .getTempleById(this.templeForm.value.city_id)
      .subscribe((response) => {
        this.templeList = response.data.temples;
      });
  }

  //get the Countrylist value for the field
  getCountry() {
    this.apiService
      .getCountryList(NetworkService.getCountry())
      .subscribe((response) => {
        this.countryList = response.data.countries;
      });
  }

  //get the Statelist value for the field
  getState(event: any) {
    this.apiService
      .getStateList(this.templeForm.value.country_id)
      .subscribe((response) => {
        this.statesList = response.data.states;
      });
  }

  //get the Citylist value for the field
  getCity(event: any) {
    this.apiService
      .getCityList(this.templeForm.value.state_id)
      .subscribe((response) => {
        this.cityList = response.data.cities;
      });
  }

  // Delete Data
  delete(_item: any) {
    // Populate the form with the selected row data
    this.templeForm.patchValue({
      id: _item.id,
      temple: _item.temple,
    });
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          var deleteTempleForm: FormGroup;
          deleteTempleForm = this.formBuilder.group({
            id: this.templeForm.value.id,
            master_type: "temple",
          });
          // delete the table data of kulam
          this.apiService
            .deleteMaster(deleteTempleForm.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.templeForm.reset();
                this.getTempleList();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your data has been deleted.",
                  "success"
                );
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your date is safe :)",
            "error"
          );
        }
      });
  }

  /**
   *  Get the family data in csv format
   */
  exportFamilyData(_item) {
    console.log();
    const temple_id = _item.id;
    const temple_wise = _item.temple;
    this.apiService
      .getExportTempleData(temple_id)
      .subscribe((response: any) => {
        console.log(response);
        const blob = new Blob([response.body], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${temple_wise}_family_data.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  //submit the temple form
  onSubmit() {
    this.submitted = true;
    if (this.templeForm.invalid) {
      return;
    } else {
      if (this.templeForm.value.id == "" || this.templeForm.value.id == null) {
        var addTempleForm: FormGroup;
        addTempleForm = this.formBuilder.group({
          temple: this.templeForm.value.temple,
          city_id: this.templeForm.value.city_id,
          address: this.templeForm.value.address,
        });

        //post the temple value in the DB
        this.apiService.addTemple(addTempleForm.value).subscribe((response) => {
          if (response.error == false) {
            this.templeForm.reset();
            this.getTempleList();
          }
        });
      } else {
        var updateTempleForm: FormGroup;
        updateTempleForm = this.formBuilder.group({
          temple: this.templeForm.value.temple,
          city_id: this.templeForm.value.city_id,
          address: this.templeForm.value.address,
          id: this.templeForm.value.id,
        });

        //Update the temple value in the DB
        this.apiService
          .updateTemple(updateTempleForm.value)
          .subscribe((response) => {
            if (response.error == false) {
              this.templeForm.reset();
              this.getTempleList();
            }
          });
      }
    }
  }

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
  }

  /**
   * edit the temple form data
   * @param content modal content
   */
  editDataGet(_item: any, content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
    var modelTitle = document.querySelector(".modal-title") as HTMLAreaElement;
    modelTitle.innerHTML = "Edit Kulam";
    var updateBtn = document.getElementById("add-btn") as HTMLAreaElement;
    updateBtn.innerHTML = "Update";

    // Populate the state field with the help of country_id
    this.templeForm.patchValue({
      country_id: _item.country_id,
    });
    this.getState(event);

    // Populate the city field with the help of state_id
    this.templeForm.patchValue({
      state_id: _item.state_id,
    });
    this.getCity(event);

    //populate all the form field
    this.templeForm.patchValue({
      id: _item.id,
      country_id: _item.country_id,
      state_id: _item.state_id,
      city_id: _item.city_id,
      temple: _item.temple,
      address: _item.address,
    });
  }

  // close the form and reset the form field value is empty
  closeForm() {
    this.templeForm.reset();
  }

  // Enable the clearable symbol in country dorpdown
  isCountryDropdownClearable(): boolean {
    const selectedCountry = this.countryList.find(
      (country) => country.id === this.templeForm.get("country_id").value
    );
    return !selectedCountry || !selectedCountry.country;
  }

  // Enable the clearable symbol in state dorpdown
  isStateDropdownClearable(): boolean {
    const selectedState = this.statesList.find(
      (state) => state.id === this.templeForm.get("state_id").value
    );
    return !selectedState || !selectedState.state_name;
  }

  // Enable the clearable symbol in city dorpdown
  isCityDropdownClearable(): boolean {
    const selectedCity = this.cityList.find(
      (city) => city.id === this.templeForm.get("city_id").value
    );
    return !selectedCity || !selectedCity.city_name;
  }
}
