import { Component } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { ApiService } from "src/app/services/api.service";
import { NetworkService } from "../services/network.service";

@Component({
  selector: "app-adminlist",
  templateUrl: "./adminlist.component.html",
  styleUrls: ["./adminlist.component.scss"],
  providers: [DecimalPipe],
})

/**
 * Ecommerce orders component
 */
export class AdminlistComponent {
  //Below all functionality are added by Giriprasath R

  adminList: any[] = []; // storing the adminlist in the array
  templeList: any[] = []; // storing the templelist in the array
  countryList: any[] = []; //  storing the countrylist in the array
  statesList: any[] = []; // storing the stateslist in the array
  cityList: any[] = []; // storing the cityList in the array
  adminForm: FormGroup;
  submitted = false;

  filteredAdminList: any[] = []; // Filtered adminList to be displayed
  searchTerm: string = ""; // Search term entered by the user

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiServices: ApiService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getAdmin();
    this.getCountry();
  }

  // Function to filter adminList based on search term
  filterAdminList() {
    if (this.searchTerm.trim() === "") {
      this.filteredAdminList = this.adminList;
    } else {
      this.filteredAdminList = this.adminList.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  initForm() {
    //Form Validations
    this.adminForm = this.formBuilder.group({
      id: [""],
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      temple_id: ["", Validators.required],
      city_id: ["", Validators.required],
      state_id: ["", Validators.required],
      country_id: ["", Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.adminForm.controls;
  }

  //get the Adminlist value for the field
  getAdmin() {
    const timestamp = new Date().getTime();
    this.apiServices
      .getAdminList(`${NetworkService.getAdminList()}?_=${timestamp}`)
      .subscribe((response) => {
        this.adminList = response.data.users;
        this.filterAdminList();
      });
  }

  //get the Temple value for the field
  getTemple(event: any) {
    this.apiServices
      .getTempleById(this.adminForm.value.city_id)
      .subscribe((response) => {
        this.templeList = response.data.temples;
      });
  }

  //get the Countrylist value for the field
  getCountry() {
    this.apiServices
      .getCountryList(NetworkService.getCountry())
      .subscribe((response) => {
        this.countryList = response.data.countries;
      });
  }

  //get the Statelist value for the field
  getState(event: any) {
    this.apiServices
      .getStateList(this.adminForm.value.country_id)
      .subscribe((response) => {
        this.statesList = response.data.states;
      });
  }

  //get the Citylist value for the field
  getCity(event: any) {
    this.apiServices
      .getCityList(this.adminForm.value.state_id)
      .subscribe((response) => {
        this.cityList = response.data.cities;
      });
  }

  // Delete Data
  delete(_item: any) {
    // Select the row data
    this.adminForm.patchValue({
      id: _item.id,
    });
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          var deleteAdminForm: FormGroup;
          deleteAdminForm = this.formBuilder.group({
            id: this.adminForm.value.id,
          });

          // delete the table data of Admin
          this.apiServices
            .deleteAdmin(deleteAdminForm.value)
            .subscribe((response) => {
              if (response.error == false) {
                this.adminForm.reset();
                this.getAdmin();
              }
            });
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.adminForm.invalid) {
      return;
    } else {
      if (this.adminForm.value.id == "" || this.adminForm.value.id == null) {
        var addAdminForm: FormGroup;
        addAdminForm = this.formBuilder.group({
          name: this.adminForm.value.name,
          email: this.adminForm.value.email,
          temple_id: this.adminForm.value.temple_id,
        });

        //Post the Admin data to the DB
        this.apiServices.addAdmin(addAdminForm.value).subscribe((response) => {
          if (response.error == false) {
            this.adminForm.reset();
            this.getAdmin();
          }
        });
      } else {
        var updateAdminForm: FormGroup;
        updateAdminForm = this.formBuilder.group({
          id: this.adminForm.value.id,
          name: this.adminForm.value.name,
          email: this.adminForm.value.email,
          temple_id: this.adminForm.value.temple_id,
        });
        //Update the Admin data to the DB
        this.apiServices
          .updateAdmin(updateAdminForm.value)
          .subscribe((response) => {
            if (response.error == false) {
              this.adminForm.reset();
              this.getAdmin();
            }
          });
      }
    }
  }

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
  }

  /**
   * edit the admin modal
   * @param content modal content
   */
  editDataGet(_item: any, content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
    var modelTitle = document.querySelector(".modal-title") as HTMLAreaElement;
    modelTitle.innerHTML = "Edit Admin";
    var updateBtn = document.getElementById("add-btn") as HTMLAreaElement;
    updateBtn.innerHTML = "Update";

    // Populate the state field with the help of country_id
    this.adminForm.patchValue({
      country_id: _item.country_id,
    });
    this.getState(event);

    // Populate the city field with the help of state_id
    this.adminForm.patchValue({
      state_id: _item.state_id,
    });
    this.getCity(event);

    // Populate the temple field with the help of city_id
    this.adminForm.patchValue({
      city_id: _item.city_id,
    });
    this.getTemple(event);

    // Populate the value of the adminform
    this.adminForm.patchValue({
      id: _item.id,
      name: _item.name,
      email: _item.email,
      temple_id: _item.temple_id,
    });
  }
  // close the form and reset the form field value is empty
  closeForm() {
    this.adminForm.reset();
  }

  // Enable the clearable symbol in country dorpdown
  isCountryDropdownClearable(): boolean {
    const selectedCountry = this.countryList.find(
      (country) => country.id === this.adminForm.get("country_id").value
    );
    return !selectedCountry || !selectedCountry.country;
  }

  // Enable the clearable symbol in state dorpdown
  isStateDropdownClearable(): boolean {
    const selectedState = this.statesList.find(
      (state) => state.id === this.adminForm.get("state_id").value
    );
    return !selectedState || !selectedState.state_name;
  }

  // Enable the clearable symbol in city dorpdown
  isCityDropdownClearable(): boolean {
    const selectedCity = this.cityList.find(
      (city) => city.id === this.adminForm.get("city_id").value
    );
    return !selectedCity || !selectedCity.city_name;
  }

  // Enable the clearable symbol in temple dorpdown
  isTempleDropdownClearable(): boolean {
    const selectedTemple = this.templeList.find(
      (temple) => temple.id === this.adminForm.get("temple_id").value
    );
    return !selectedTemple || !selectedTemple.temple;
  }
}
