<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Free Register</h5>
                  <p>Get your free JanaVirucham account now.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="assets/images/profile-img.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="assets/images/logo.svg"
                      alt=""
                      class="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form
                class="needs-validation"
                name="signupForm"
                [formGroup]="signupForm"
                (ngSubmit)="onSubmit()"
                novalidate
              >
                <div class="row">
                  <div class="mb-3">
                    <input
                      type="radio"
                      formControlName="gender"
                      value="male"
                      id="male"
                    />
                    <label for="male">Male</label>
                    <input
                      type="radio"
                      formControlName="gender"
                      value="female"
                      id="female"
                      class="female-gender-align"
                    />
                    <label for="female">Female</label>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Name</label>

                  <input
                    type="text"
                    formControlName="name"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                    id="name"
                    placeholder="User Name"
                  />

                  <div
                    *ngIf="submitted && f.name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.name.errors.required">Name is required</div>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Father Name</label>

                  <input
                    type="text"
                    formControlName="father_name"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && f.father_name.errors
                    }"
                    id="father_name"
                    placeholder="Father Name"
                  />

                  <div
                    *ngIf="submitted && f.father_name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.father_name.errors.required">
                      Father name is required
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Email</label>

                  <input
                    type="email"
                    formControlName="email"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    id="email"
                    placeholder="Email"
                  />

                  <div
                    *ngIf="submitted && f.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Phone Number</label>

                  <input
                    type="text"
                    formControlName="phone_number"
                    class="form-control"
                    id="phone_number"
                    placeholder="Mobile Number"
                    [ngClass]="{
                      'is-invalid': submitted && f.phone_number.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f.phone_number.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.phone_number.errors.required">
                      Mobile number is required
                    </div>
                    <div *ngIf="f.password.errors.pattern">
                      Mobile number must be 10 digit
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      type="password"
                      formControlName="password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.password.errors
                      }"
                      id="password"
                      placeholder="Password"
                    />

                    <button
                      class="btn btn-light ms-0"
                      type="button"
                      (click)="passwordVisibility()"
                    >
                      <i class="mdi mdi-eye-outline"></i>
                    </button>
                    <div
                      *ngIf="submitted && f.password.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.password.errors.required">
                        Password is required
                      </div>
                      <div *ngIf="f.password.errors.minlength">
                        Password must be at 8 characters
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Country</label>

                    <ng-select
                      [items]="countryList"
                      bindLabel="country"
                      bindValue="id"
                      formControlName="country_id"
                      [multiple]="false"
                      [clearable]="!isCountryDropdownClearable()"
                      (change)="state($event)"
                      [ngClass]="{
                        'is-invalid': submitted && f.country_id.errors
                      }"
                    ></ng-select>

                    <div
                      *ngIf="submitted && f.country_id.errors"
                      class="invalid-feedback"
                    >
                      <span *ngIf="f.country_id.errors.required"
                        >Please select a country.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">State</label>

                      <ng-select
                        [items]="statesList"
                        bindLabel="state_name"
                        bindValue="id"
                        formControlName="state_id"
                        [multiple]="false"
                        [clearable]="!isStateDropdownClearable()"
                        (change)="city($event)"
                        [ngClass]="{
                          'is-invalid': submitted && f.state_id.errors
                        }"
                      ></ng-select>

                      <div
                        *ngIf="submitted && f.state_id.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.state_id.errors.required"
                          >Please select a state.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">City</label>

                      <ng-select
                        [items]="cityList"
                        bindLabel="city_name"
                        bindValue="id"
                        formControlName="city_id"
                        [multiple]="false"
                        [clearable]="!isCityDropdownClearable()"
                        (change)="temple($event)"
                        [ngClass]="{
                          'is-invalid': submitted && f.city_id.errors
                        }"
                      ></ng-select>

                      <div
                        *ngIf="submitted && f.city_id.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.city_id.errors.required"
                          >Please select a city.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Kulam</label>

                      <ng-select
                        [items]="kulamList"
                        bindLabel="kula"
                        bindValue="id"
                        formControlName="kula_id"
                        (change)="kothram($event)"
                        [multiple]="false"
                        [clearable]="!isKulamDropdownClearable()"
                        [ngClass]="{
                          'is-invalid': submitted && f.kula_id.errors
                        }"
                      ></ng-select>

                      <div
                        *ngIf="submitted && f.kula_id.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.kula_id.errors.required"
                          >Please select a kulam.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Kothram</label>

                      <ng-select
                        [items]="gotramList"
                        bindLabel="gotra"
                        bindValue="id"
                        formControlName="gotra_id"
                        [multiple]="false"
                        [clearable]="!isKothramDropdownClearable()"
                        [ngClass]="{
                          'is-invalid': submitted && f.gotra_id.errors
                        }"
                      ></ng-select>

                      <div
                        *ngIf="submitted && f.gotra_id.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.gotra_id.errors.required"
                          >Please select a Gothram.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Temple Name</label>

                      <ng-select
                        [items]="templeList"
                        bindLabel="temple"
                        bindValue="id"
                        formControlName="temple_id"
                        [multiple]="false"
                        [clearable]="!isTempleDropdownClearable()"
                        [ngClass]="{
                          'is-invalid': submitted && f.temple_id.errors
                        }"
                      ></ng-select>

                      <div
                        *ngIf="submitted && f.temple_id.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.temple_id.errors.required"
                          >Please select a Temple Name.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Date Of Birth</label>
                      <mat-form-field class="w-100" appearance="outline">
                        <input
                          matInput
                          [matDatepicker]="picker"
                          id="dob"
                          formControlName="dob"
                          (click)="picker.open()"
                          [ngClass]="{
                            'is-invalid': submitted && f.dob.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && f.dob.errors"
                          class="invalid-feedback"
                        >
                          <span
                            *ngIf="f.dob.errors.required"
                            class="errorMessage"
                            >Please provide a valid Birth Details.</span
                          >
                        </div>
                        <mat-datepicker-toggle
                          class="datePickerDesign"
                          matIconSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="mt-4 d-grid">
                  <button class="btn btn-primary" type="submit">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            Already have an account ?
            <a routerLink="/login" class="fw-medium text-primary"> Login</a>
          </p>
          <p>
            © {{ year }} JanaVirucham. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Ero Edge
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
