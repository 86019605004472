<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<div class="container-fluid" *ngIf="approvePendingList.length >= 1">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterApprovePendingList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Family Name</th>
                  <th class="align-middle">Created By</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of approvePendingList"
                  id="{{ item.pendingApprovals }}"
                >
                  <td>
                    {{ item.family_name }}
                  </td>
                  <td>
                    {{ item.created_by }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="familyView(item)"
                        ><i class="mdi mdi-eye-outline font-size-18"></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="approve(item)"
                        ><i class="mdi mdi-check font-size-18"></i> Approve</a
                      >
                      <a
                        href="javascript: void(0);"
                        class="text-danger"
                        (click)="reject(item)"
                        ><i class="mdi mdi-window-close font-size-18"></i>
                        Reject</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- No data has been found the below div will shown on the component -->
<div class="account-pages my-5 pt-5" *ngIf="approvePendingList.length < 1">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <div class="mt-5 text-center">
            <h3 class="text-primary">
              Sorry, No approve/pending list assigned to you.
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
