<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>

<div
  class="container-fluid"
  *ngIf="userTypeId === '1' && familyList.length >= 1"
>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterFamilyList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button
                  type="button"
                  class="btn btn-success btn-rounded mb-2 me-2"
                  (click)="addFamilyDetailsPage()"
                >
                  <i class="mdi mdi-plus mr-1"></i> Add Family
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Created By</th>
                  <th class="align-middle">Created Date</th>
                  <th class="align-middle">Status</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredFamilyList"
                  id="{{ item.created_by }}"
                >
                  <td>
                    {{ item.created_by }}
                  </td>
                  <td>
                    {{ formatDate(item.created_date) }}
                  </td>
                  <td>
                    {{
                      item.status == "approved" ||
                      item.status == "rejected" ||
                      item.status == "pending"
                        ? camelCase(item.status)
                        : item.status
                    }}
                  </td>
                  <td>
                    <div class="d-flex gap-3">
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="familyView(item)"
                        ><i
                          class="mdi mdi-eye-outline font-size-18"
                          *ngIf="item.status == 'approved'"
                        ></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="editFamilyDetailData()"
                        ><i
                          class="mdi mdi-pencil font-size-18"
                          *ngIf="item.status == 'approved'"
                        ></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        class="text-danger"
                        (click)="delete(item)"
                        ><i class="mdi mdi-delete font-size-18"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="container-fluid"
  *ngIf="userTypeId === '2' && familyList.length >= 1"
>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterFamilyList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Created By</th>
                  <th class="align-middle">Created Date</th>
                  <th class="align-middle">Status</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredFamilyList"
                  id="{{ item.created_by }}"
                >
                  <td>
                    {{ item.created_by }}
                  </td>
                  <td>
                    {{ formatDate(item.created_date) }}
                  </td>
                  <td>
                    {{
                      item.status == "approved" ||
                      item.status == "rejected" ||
                      item.status == "pending"
                        ? camelCase(item.status)
                        : item.status
                    }}
                  </td>
                  <td>
                    <div>
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="familyView(item)"
                        ><i
                          class="mdi mdi-eye-outline font-size-18"
                          *ngIf="item.status == 'approved'"
                        ></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        (click)="exportFamilyData(item)"
                        ><i
                          class="mdi mdi-application-export font-size-18 export_btn_align"
                          *ngIf="item.status == 'approved'"
                        ></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="container-fluid"
  *ngIf="userTypeId === '3' && familyList.length >= 1"
>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-sm-4" style="margin-top: 30px">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchTerm"
                    (input)="filterFamilyList()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>

            <div class="col-sm-8 row dropdown-align country_dropdown_margin">
              <div class="col-lg-3">
                <div class="mb-3">
                  <label class="form-label">Country</label>
                  <ng-select
                    [items]="countryList"
                    bindLabel="country"
                    bindValue="id"
                    [multiple]="false"
                    [clearable]="true"
                    class="custom-radius"
                    (change)="getState($event)"
                  ></ng-select>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-3">
                  <label class="form-label">State</label>
                  <ng-select
                    [items]="statesList"
                    bindLabel="state_name"
                    bindValue="id"
                    [multiple]="false"
                    [clearable]="true"
                    class="custom-radius"
                    (change)="getCity($event)"
                  ></ng-select>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-3">
                  <label class="form-label">City</label>

                  <ng-select
                    [items]="cityList"
                    bindLabel="city_name"
                    bindValue="id"
                    [multiple]="false"
                    [clearable]="true"
                    (change)="getTemple($event)"
                  ></ng-select>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-3">
                  <label class="form-label textname-wrap">Temple Name</label>

                  <ng-select
                    [items]="templeList"
                    bindLabel="temple"
                    bindValue="id"
                    [multiple]="false"
                    [clearable]="true"
                    (change)="getFamilyListByTempleId($event)"
                  ></ng-select>
                </div>
              </div>
            </div>
            <!-- end col-->
          </div>

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="table-light">
                <tr>
                  <th class="align-middle">Created By</th>
                  <th class="align-middle">Created Date</th>
                  <th class="align-middle">Status</th>
                  <th class="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of filteredFamilyList"
                  id="{{ item.created_by }}"
                >
                  <td>
                    {{ item.created_by }}
                  </td>
                  <td>
                    {{ formatDate(item.created_date) }}
                  </td>
                  <td>
                    {{
                      item.status == "approved" ||
                      item.status == "rejected" ||
                      item.status == "pending"
                        ? camelCase(item.status)
                        : item.status
                    }}
                  </td>
                  <td>
                    <div>
                      <a
                        href="javascript: void(0);"
                        class="text-success"
                        (click)="familyView(item)"
                        ><i
                          class="mdi mdi-eye-outline font-size-18"
                          *ngIf="item.status == 'approved'"
                        ></i
                      ></a>
                      <a
                        href="javascript: void(0);"
                        (click)="exportFamilyData(item)"
                        ><i
                          class="mdi mdi-application-export font-size-18 export_btn_align"
                          *ngIf="item.status == 'approved'"
                        ></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- No data has been found the below three div will shown on the component based on the condition -->
<div
  class="account-pages my-5 pt-5"
  *ngIf="userTypeId === '1' && familyList.length < 1"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <h4 class="text-primary">Welcome To JanaVirucham.</h4>
          <div class="mt-5 text-center">
            <a class="btn btn-primary" routerLink="/addfamily">Add Family</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="account-pages my-5 pt-5"
  *ngIf="userTypeId === '2' && familyList.length < 1"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <div class="mt-5 text-center">
            <h4 class="text-primary">
              Sorry, No family details has been assigned.
            </h4>
            <div class="mt-5 text-center">
              <a class="btn btn-primary" (click)="reloadPage()"
                >Family Details</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="account-pages my-5 pt-5"
  *ngIf="userTypeId === '3' && familyList.length < 1"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center mb-5">
          <div class="mt-5 text-center">
            <h4 class="text-primary">
              Sorry, No family details has been assigned.
            </h4>
            <div class="mt-5 text-center">
              <a class="btn btn-primary" (click)="reloadPage()"
                >Family Details</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-xl-6">
        <div>
          <img src="assets/images/error-img.png" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
