import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { PasswordresetComponent } from "./passwordreset/passwordreset.component";
import { OtpverifyComponent } from "./otpverify/otpverify.component";
import { KothramComponent } from "./kothram/kothram.component";
import { KulamComponent } from "./kulam/kulam.component";
import { AdminlistComponent } from "./adminlist/adminlist.component";
import { AddfamilydetailsComponent } from "./addfamilydetails/addfamilydetails.component";
import { LayoutComponent } from "./layouts/layout.component";
import { TempleComponent } from "./temple/temple.component";
import { AuthGuard } from "./guards/auth.guard";
import { GetfamilydetailsComponent } from "./getfamilydetails/getfamilydetails.component";
import { ProfileComponent } from "./profile/profile.component";
import { ViewfamilydetailsComponent } from "./viewfamilydetails/viewfamilydetails.component";
import { ApprovePendingListComponent } from "./approve-pending-list/approve-pending-list.component";
import { MergefamilyComponent } from "./mergefamily/mergefamily.component";
import { BulkMessageComponent } from "./bulk-message/bulk-message.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "reset-password",
    component: PasswordresetComponent,
  },
  {
    path: "otpverify",
    component: OtpverifyComponent,
  },
  {
    path: "profile",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: ProfileComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "kothram",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: KothramComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "kulam",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: KulamComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "adminlist",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: AdminlistComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "addfamily",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: AddfamilydetailsComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "getfamily",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: GetfamilydetailsComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "addtemple",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: TempleComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "viewfamilydetails",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: ViewfamilydetailsComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "approvePendingList",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: ApprovePendingListComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "mergeFamily",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: MergefamilyComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "bulkMessage",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: BulkMessageComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
