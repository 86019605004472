import { Component } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { SharedValueService } from "../services/share-values.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

@Component({
  selector: "app-addfamilydetails",
  templateUrl: "./addfamilydetails.component.html",
  styleUrl: "./addfamilydetails.component.scss",
})
export class AddfamilydetailsComponent {
  //Below all functionality are added by Giriprasath R

  //For datepicker automatic declaration
  [x: string]: any;

  //For checking the mother and fathe date of birth value is correct or not
  isUpdating = false;
  momDob: any;
  dadDob: any;
  motherDob: any;
  fatherDob: any;

  //Form step moving by this values
  currentStep = 1;
  totalSteps = 4;

  //Restrict the api calling next step flow
  nextSteps = 0;

  customerDetailsValue: any; //storing the customer details in this variable

  validationform: FormGroup; // bootstrap validation form
  validationform_1: FormGroup; // bootstrap validation form-1
  validationform_2: FormGroup; // bootstrap validation form-1
  validationform_3: FormGroup; // bootstrap validation form-1

  submit: boolean = false; // Flag to indicate form submission

  step1FormData: any;
  step2FormData: any;
  step3FormData: any;

  mother_alive: number = 0;
  is_mom_dad_divorced: number = 0;
  is_mom_dad_mother_divorced: number = 0;
  is_father_dad_mother_divorced: number = 0;
  father_alive: number = 0;
  mom_mother_alive: number = 0;
  mom_father_alive: number = 0;
  dad_mother_alive: number = 0;
  dad_father_alive: number = 0;
  is_user_family_captain: number = 0;
  is_dad_family_captain: number = 0;
  is_mom_family_captain: number = 0;
  mom_father_is_family_captain: number = 0;
  mom_mother_is_family_captain: number = 0;
  dad_father_is_family_captain: number = 0;
  dad_mother_is_family_captain: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService,
    private router: Router,
    private sharedValueService: SharedValueService
  ) {}

  ngOnInit() {
    // Getting the customer details value from loginComponent by using service file
    this.customerDetailsValue = this.sharedValueService.customerDetailsValue;

    /**
     * Validation form data
     */
    this.validationform = this.formBuilder.group({
      user_name: [this.customerDetailsValue[0], Validators.required],
      father_name: [this.customerDetailsValue[1], Validators.required],
      mother_name: ["", Validators.required],
      father_dob: ["", Validators.required],
      mother_dob: ["", Validators.required],
      user_gender: [this.customerDetailsValue[2], Validators.required],
      user_dob: [this.customerDetailsValue[3], Validators.required],
      user_phone_number: [
        this.customerDetailsValue[4],
        [Validators.pattern(/^(|[0-9]{10})$/)],
      ],
      mother_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      father_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      is_user_family_captain: [this.is_user_family_captain],
      is_dad_family_captain: [this.is_dad_family_captain],
      is_mom_family_captain: [this.is_mom_family_captain],
    });

    /**
     * Validation form-1 data
     */
    this.validationform_1 = this.formBuilder.group({
      father_name: ["", Validators.required],
      mother_name: ["", Validators.required],
      father_dob: ["", Validators.required],
      mother_dob: ["", Validators.required],
      is_divorced: [this.is_mom_dad_divorced],
      mother_alive: [this.mother_alive],
      father_alive: [this.father_alive],
      user_gender: [this.validationform.value.user_gender],
      user_name: [this.validationform.value.user_name],
      user_dob: [this.validationform.value.user_dob],
      user_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      mother_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      father_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      is_user_family_captain: [""],
      is_dad_family_captain: [""],
      is_mom_family_captain: [this.validationform.value.is_mom_family_captain],
    });

    /**
     * Validation form-2 data
     */
    this.validationform_2 = this.formBuilder.group({
      mom_father_name: ["", Validators.required],
      mom_mother_name: ["", Validators.required],
      mom_father_dob: ["", Validators.required],
      mom_mother_dob: ["", Validators.required],
      mom_mother_alive: [this.mom_mother_alive],
      mom_father_alive: [this.mom_father_alive],
      is_divorced: [this.is_mom_dad_mother_divorced],
      daughter_id: [""],
      family_tree_id: [""],
      mom_mother_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      mom_father_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      mom_father_is_family_captain: [this.mom_father_is_family_captain],
      mom_mother_is_family_captain: [this.mom_mother_is_family_captain],
    });

    /**
     * Validation form-3 data
     */
    this.validationform_3 = this.formBuilder.group({
      dad_father_name: ["", Validators.required],
      dad_mother_name: ["", Validators.required],
      dad_father_dob: ["", Validators.required],
      dad_mother_dob: ["", Validators.required],
      dad_mother_alive: [this.dad_mother_alive],
      dad_father_alive: [this.dad_father_alive],
      is_divorced: [this.is_father_dad_mother_divorced],
      son_id: [""],
      family_tree_id: [""],
      dad_mother_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      dad_father_phone_number: ["", [Validators.pattern(/^(|[0-9]{10})$/)]],
      dad_father_is_family_captain: [this.dad_father_is_family_captain],
      dad_mother_is_family_captain: [this.dad_mother_is_family_captain],
    });
  }

  // Modify the date value
  convertToUTC(dateValue: Date): string {
    // Provided date string
    const dateString = dateValue;

    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Add one day to the date
    date.setDate(date.getDate() + 1);

    // Convert the date to a string in ISO 8601 format (YYYY-MM-DDTHH:MM:SS.sssZ)
    const adjustedDateString = date.toISOString().split("T")[0];
    return adjustedDateString;
  }

  //If any modification is occured in the field the below function will be call based on method calling
  genderMaleChange() {
    this.validationform_1.patchValue({
      user_gender: this.validationform.get("user_gender").value,
    });
  }

  genderFemaleChange() {
    this.validationform_1.patchValue({
      user_gender: this.validationform.get("user_gender").value,
    });
  }

  userNameChange() {
    this.validationform_1.patchValue({
      user_name: this.validationform.get("user_name").value,
    });
  }

  fatheNameChange() {
    this.validationform_1.patchValue({
      father_name: this.validationform.get("father_name").value,
    });
  }

  userDOBChange(event: MatDatepickerInputEvent<Date>) {
    this.validationform_1.patchValue({
      user_dob: this.convertToUTC(this.validationform.get("user_dob").value),
    });
  }

  //storing the mother dob value for checking purpose(getting the value from validation form)
  momDobChange() {
    this.momDob = this.validationform.get("mother_dob").value;
  }

  //storing the father dob value for checking purpose(getting the value from validation form)
  dadDobChange() {
    this.dadDob = this.validationform.get("father_dob").value;
  }

  //storing the mother dob value for checking purpose(getting the value from validation form1)
  motherDobChange() {
    // If the function is already updating, do nothing
    if (this.isUpdating) {
      return;
    }
    // Set the flag to indicate the function is updating
    this.isUpdating = true;
    this.motherDob = this.validationform_1.get("mother_dob").value;
    if (this.momDob !== this.motherDob) {
      this.validationform_1.patchValue({
        mother_dob: this.convertToUTC(this.motherDob),
      });
    }
    this.isUpdating = false;
  }

  //storing the father dob value for checking purpose(getting the value from validation form1)
  fatherDobChange() {
    // If the function is already updating, do nothing
    if (this.isUpdating) {
      return;
    }
    // Set the flag to indicate the function is updating
    this.isUpdating = true;
    this.fatherDob = this.validationform_1.get("father_dob").value;
    if (this.dadDob !== this.fatherDob) {
      this.validationform_1.patchValue({
        father_dob: this.convertToUTC(this.fatherDob),
      });
    }
    this.isUpdating = false;
  }

  //Get the checkbox value for the[Family captain is user or not] api flow
  userFamilyCaptain() {
    const userCaptain = (
      document.getElementById("userCaptain") as HTMLInputElement
    ).checked;

    this.is_user_family_captain = userCaptain ? 1 : 0;
    this.is_dad_family_captain = userCaptain ? 0 : this.is_dad_family_captain;
    this.validationform.patchValue({
      is_user_family_captain: this.is_user_family_captain,
      is_dad_family_captain: this.is_dad_family_captain,
    });

    // Uncheck the other checkbox if this one is checked
    if (userCaptain) {
      (document.getElementById("userDadCaptain") as HTMLInputElement).checked =
        false;
    }
  }

  //Get the checkbox value for the[Family captain is father or not] api flow
  DadFamilyCaptain() {
    const userDadCaptain = (
      document.getElementById("userDadCaptain") as HTMLInputElement
    ).checked;

    this.is_dad_family_captain = userDadCaptain ? 1 : 0;
    this.is_user_family_captain = userDadCaptain
      ? 0
      : this.is_user_family_captain;
    this.validationform.patchValue({
      is_dad_family_captain: this.is_dad_family_captain,
      is_user_family_captain: this.is_user_family_captain,
    });

    // Uncheck the other checkbox if this one is checked
    if (userDadCaptain) {
      (document.getElementById("userCaptain") as HTMLInputElement).checked =
        false;
    }
  }

  //Get checkbox value for the[Mother and Father is alive or not] api flow
  getCheckBoxValue_1() {
    const checkbox1 = (document.getElementById("checkbox1") as HTMLInputElement)
      .checked;
    const checkbox2 = (document.getElementById("checkbox2") as HTMLInputElement)
      .checked;

    this.mother_alive = checkbox1 ? 1 : 0;
    this.father_alive = checkbox2 ? 1 : 0;

    this.validationform_1.patchValue({
      mother_alive: this.mother_alive,
      father_alive: this.father_alive,
    });
  }

  //Get checkbox value for the[momMother and MomFather is alive or not] api flow
  getCheckBoxValue_2() {
    const checkbox3 = (document.getElementById("checkbox3") as HTMLInputElement)
      .checked;
    const checkbox4 = (document.getElementById("checkbox4") as HTMLInputElement)
      .checked;

    this.mom_mother_alive = checkbox3 ? 1 : 0;
    this.mom_father_alive = checkbox4 ? 1 : 0;

    this.validationform_2.patchValue({
      mom_mother_alive: this.mom_mother_alive,
      mom_father_alive: this.mom_father_alive,
    });
  }

  //Get checkbox value for the[dadMother and dadFather is alive or not] api flow
  getCheckBoxValue_3() {
    const checkbox5 = (document.getElementById("checkbox5") as HTMLInputElement)
      .checked;
    const checkbox6 = (document.getElementById("checkbox6") as HTMLInputElement)
      .checked;

    this.dad_mother_alive = checkbox5 ? 1 : 0;
    this.dad_father_alive = checkbox6 ? 1 : 0;

    this.validationform_3.patchValue({
      dad_mother_alive: this.dad_mother_alive,
      dad_father_alive: this.dad_father_alive,
    });
  }
  /**
   * Returns form
   */
  get form_1() {
    return this.validationform.controls;
  }
  get form_2() {
    return this.validationform_1.controls;
  }
  get form_3() {
    return this.validationform_2.controls;
  }
  get form_4() {
    return this.validationform_3.controls;
  }

  /**
   * Form-1 moves to next step (from form-1 to form-2)
   */
  nextStep() {
    //Throw an error if gender is not selected
    const genderValue = this.validationform.get("user_gender").value;
    if (genderValue === "") {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      Toast.fire("Please select your gender");
    } else if (this.validationform.invalid) {
      this.submit = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else {
      this.step1FormData = this.validationform.value; // Store form data for step 1
      this.submit = false; // Reset submit flag when moving to the next step
      this.currentStep++; // Move to next step
      this.validationform_1.patchValue({
        father_name: this.validationform.value.father_name,
        mother_name: this.validationform.value.mother_name,
        father_dob: this.convertToUTC(this.validationform.value.father_dob),
        mother_dob: this.convertToUTC(this.validationform.value.mother_dob),
        is_divorced: this.is_mom_dad_divorced,
        user_phone_number: this.validationform.value.user_phone_number,
        mother_phone_number: this.validationform.value.mother_phone_number,
        father_phone_number: this.validationform.value.father_phone_number,
        is_user_family_captain:
          this.validationform.value.is_user_family_captain,
        is_dad_family_captain: this.validationform.value.is_dad_family_captain,
      });
    }
  }

  /**
   * Form-2 moves to next step (from form-2 to form-3)
   */
  nextStep_2() {
    if (this.validationform_1.invalid) {
      this.submit = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else if (this.nextSteps === 0) {
      this.nextSteps = 1;
      this.step2FormData = this.validationform_1.value; // Store form data for step 2
      this.submit = false; // Reset submit flag when moving to the next step
      this.currentStep++; // Move to next step

      //  //Post the father and mother data to the DB
      this.apiServices
        .addFatherMother(this.validationform_1.value)
        .subscribe((response) => {
          if (response.error == false) {
            this.validationform_2.patchValue({
              daughter_id: response.data.daughter_id,
              family_tree_id: response.data.family_tree_id,
            });
            this.validationform_3.patchValue({
              son_id: response.data.son_id,
              family_tree_id: response.data.family_tree_id,
            });
          }
        });
    } else if (this.nextSteps == 1) {
      this.nextSteps = 1;
      this.currentStep++; // Move to next step
    } else {
      this.nextSteps = 2;
      this.currentStep++; // Move to next step
    }
  }

  /**
   * Form-3 moves to next step (from form-3 to form-4)
   */
  nextStep_3() {
    if (this.validationform_2.invalid) {
      this.submit = true; // Show validation errors if form is submitted or if it's invalid
      return;
    } else if (this.nextSteps === 1) {
      this.nextSteps = 2;
      this.submit = false; // Reset submit flag when moving to the next step
      this.currentStep++; // Move to next step
      this.validationform_2.patchValue({
        mom_mother_dob: this.convertToUTC(
          this.validationform_2.get("mom_mother_dob").value
        ),
        mom_father_dob: this.convertToUTC(
          this.validationform_2.get("mom_father_dob").value
        ),
      });
      this.step3FormData = this.validationform_2.value; // Store form data for step 3
      //Post the Mom father and mother data to the DB
      this.apiServices
        .addMomFatherMother(this.validationform_2.value)
        .subscribe((response) => {});
    } else {
      this.nextSteps = 2;
      this.currentStep++; // Move to next step
    }
  }

  /**
   * Form submit method (Final Form)
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform_3.invalid) {
      return;
    } else if (this.nextSteps === 2) {
      this.validationform_3.patchValue({
        dad_mother_dob: this.convertToUTC(
          this.validationform_3.get("dad_mother_dob").value
        ),
        dad_father_dob: this.convertToUTC(
          this.validationform_3.get("dad_father_dob").value
        ),
      });
      //  //Post the Dad father and mother data to the DB
      this.apiServices
        .addDadFatherMother(this.validationform_3.value)
        .subscribe((response) => {
          if (response.error == false) {
            this.validationform.reset();
            this.validationform_1.reset();
            this.validationform_2.reset();
            this.validationform_3.reset();
            this.router.navigate(["/getfamily"]);
          }
        });
    }
  }

  /**
   * Form previous step function
   */
  previousStep_1() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.validationform.setValue(this.step1FormData);
    }
  }

  /**
   * Form previous step function
   */
  previousStep_2() {
    if (this.currentStep > 2) {
      this.currentStep--;
      this.validationform_1.patchValue(this.step2FormData);
    }
  }

  /**
   * Form previous step function
   */
  previousStep_3() {
    if (this.currentStep > 3) {
      this.currentStep--;
      this.validationform_3.patchValue(this.step3FormData);
    }
  }
}
