import { NgModule } from "@angular/core";
import {
  BrowserModule,
  provideClientHydration,
} from "@angular/platform-browser";
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgOtpInputModule } from "ng-otp-input";
import { NgSelectModule } from "@ng-select/ng-select";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { PasswordresetComponent } from "./passwordreset/passwordreset.component";
import { OtpverifyComponent } from "./otpverify/otpverify.component";
import { KothramComponent } from "./kothram/kothram.component";
import { KulamComponent } from "./kulam/kulam.component";
import { AdminlistComponent } from "./adminlist/adminlist.component";
import { AddfamilydetailsComponent } from "./addfamilydetails/addfamilydetails.component";

import { LayoutsModule } from "./layouts/layouts.module";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxSpinnerService } from "ngx-spinner";
import { GetfamilydetailsComponent } from "./getfamilydetails/getfamilydetails.component";
import { ProfileComponent } from "./profile/profile.component";
import { ViewfamilydetailsComponent } from "./viewfamilydetails/viewfamilydetails.component";
import { ApprovePendingListComponent } from "./approve-pending-list/approve-pending-list.component";
import { DatePipe } from "@angular/common";
import { TempleComponent } from "./temple/temple.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { MergefamilyComponent } from "./mergefamily/mergefamily.component";
import { BulkMessageComponent } from "./bulk-message/bulk-message.component";

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    PasswordresetComponent,
    OtpverifyComponent,
    KothramComponent,
    KulamComponent,
    AdminlistComponent,
    AddfamilydetailsComponent,
    GetfamilydetailsComponent,
    TempleComponent,
    ProfileComponent,
    ViewfamilydetailsComponent,
    ApprovePendingListComponent,
    MergefamilyComponent,
    BulkMessageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbModule,
    NgOtpInputModule,
    NgSelectModule,
    LayoutsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    NgxSpinnerModule,
  ],
  providers: [
    NgxSpinnerService,
    DatePipe,
    provideClientHydration(),
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
